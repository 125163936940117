import React, {useState} from 'react';
import {TextField, Button, Select, MenuItem, FormControl, InputLabel, FormGroup} from '@mui/material';
import {Typography} from "@material-ui/core";
import recipeSourceService from '../services/recipeSourceService';

function RecipeSourceForm({onFormSubmit}) {
    const [source, setSource] = useState({
        type: '',
        title: '',
        author: '',
        ISBN: '',
        url: '',
        publicationDate: '',
        magazineIssue: '',
        description: '',
        imageUrl: '',
        notes: '',
        publisher: '',
        edition: '',
        accessDate: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSource(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Call the service to create a new source
            const createdSource = await recipeSourceService.createSource(source);
            console.log('Source created successfully:', createdSource);
            if (onFormSubmit) {
                onFormSubmit(createdSource); // Notify parent component (the modal) about the new source
            }

            // Optionally, clear the form or give some feedback to the user
            setSource({
                type: '',
                title: '',
                author: '',
                ISBN: '',
                url: '',
                publicationDate: '',
                magazineIssue: '',
                description: '',
                imageUrl: '',
                notes: '',
                publisher: '',
                edition: '',
                accessDate: '',
            });
            // If you have a callback or wish to navigate the user to another page, do it here
        } catch (error) {
            console.error('Error creating source:', error);
            // Optionally, inform the user of the failure
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h4" style={{marginBottom: '20px'}}>Add New Source</Typography>
            <FormGroup>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Type</InputLabel>
                    <Select
                        name="type"
                        value={source.type}
                        label="Type"
                        onChange={handleChange}
                    >
                        <MenuItem value="book">Book</MenuItem>
                        <MenuItem value="magazine">Magazine</MenuItem>
                        <MenuItem value="online">Online</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Title"
                    name="title"
                    value={source.title}
                    onChange={handleChange}
                    required
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Author"
                    name="author"
                    value={source.author}
                    onChange={handleChange}
                />
                {source.type === 'book' && (
                    <>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="ISBN"
                            name="ISBN"
                            value={source.ISBN}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Publisher"
                            name="publisher"
                            value={source.publisher}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Edition"
                            name="edition"
                            value={source.edition}
                            onChange={handleChange}
                            required
                        />
                    </>
                )}
                {source.type === 'magazine' && (
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Magazine Issue"
                        name="magazineIssue"
                        value={source.magazineIssue}
                        onChange={handleChange}
                        required
                    />
                )}
                {source.type === 'online' && (
                    <>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="URL"
                            name="url"
                            value={source.url}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Access Date"
                            name="accessDate"
                            type="date"
                            InputLabelProps={{shrink: true}}
                            value={source.accessDate}
                            onChange={handleChange}
                            required
                        />
                    </>
                )}
                {['book', 'magazine', 'online'].includes(source.type) && (
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Publication Date"
                        name="publicationDate"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={source.publicationDate}
                        onChange={handleChange}
                    />
                )}
                <TextField
                    margin="normal"
                    fullWidth
                    label="Description"
                    name="description"
                    value={source.description}
                    multiline
                    rows={4}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Image URL"
                    name="imageUrl"
                    value={source.imageUrl}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Notes"
                    name="notes"
                    value={source.notes}
                    multiline
                    rows={2}
                    onChange={handleChange}
                />
                <Button type="submit" variant="contained" sx={{mt: 3}}>
                    Submit
                </Button>
            </FormGroup>
        </form>
    );
}

export default RecipeSourceForm;
