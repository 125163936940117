const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token'); // Assuming token is stored in local storage

// Fetch all collections
export const getCollections = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/collections`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching collections:", error);
        throw error;
    }
};

// Create a new collection
export const createCollection = async (collectionData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/collections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(collectionData),
        });
        if (!response.ok) {
            throw new Error('Error creating collection');
        }
        return await response.json();
    } catch (error) {
        console.error("Error creating collection:", error);
        throw error;
    }
};

// Update a collection
export const updateCollection = async (id, collectionData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/collections/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(collectionData),
        });
        if (!response.ok) {
            throw new Error('Error updating collection');
        }
        return await response.json();
    } catch (error) {
        console.error("Error updating collection:", error);
        throw error;
    }
};

// Delete a collection
export const deleteCollection = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/collections/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error('Error deleting collection');
        }
        return await response.json();
    } catch (error) {
        console.error("Error deleting collection:", error);
        throw error;
    }
};
