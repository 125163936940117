import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Ensure you have your API base URL here

// Creating axios instance
const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Retrieve the token at the time of request
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const ingredientService = {
    // Fetch all ingredients
    getAllIngredients: async ({ page, category, searchTerm, sortBy } = {}) => {
        let queryString = '?';
        if (page !== undefined) {
            queryString += `page=${page}&`;
        }
        if (category) {
            queryString += `category=${encodeURIComponent(category)}&`;
        }
        if (searchTerm) {
            queryString += `search=${encodeURIComponent(searchTerm)}&`;
        }
        if (sortBy) {
            queryString += `sortBy=${encodeURIComponent(sortBy)}&`;
        }
        // Remove the last '&' or '?' from the queryString if necessary
        queryString = queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
        queryString = queryString === '?' ? '' : queryString; // If queryString is only '?', set it to empty

        const finalUrl = `/ingredients${queryString}`;
        console.log(`Requesting: ${finalUrl}`); // Debug log

        try {
            const response = await api.get(`/ingredients${queryString}`);
            return response.data; // Assuming the API returns the list of ingredients along with any other pagination metadata if necessary
        } catch (error) {
            throw error; // Propagate the error to be handled by the calling component
        }
    },

    // Add a new ingredient
    addIngredient: async (ingredientData) => {
        try {
            const response = await api.post('/ingredients', ingredientData);
            return response.data; // Assuming the API returns the created ingredient object
        } catch (error) {
            throw error; // Propagate the error to be handled by the calling component
        }
    },

    // Add to the ingredientService
    getUserIngredientsWithAvailability: async ({ page = 1, categoriesPerPage = 2 } = {}) => {
        let queryString = `?page=${page}&categoriesPerPage=${categoriesPerPage}`;

        try {
            const response = await api.get(`/ingredients/availability${queryString}`);
            return response.data; // Adjust according to actual API response structure
        } catch (error) {
            throw error;
        }
    },

    getIngredientById: async (ingredientId) => {
        try {
            const response = await api.get(`/ingredients/${ingredientId}`);
            return response.data; // Assuming the API returns the ingredient object
        } catch (error) {
            throw error;
        }
    },

};

export default ingredientService;
