import React, {useState} from 'react';
import {Link} from "react-router-dom";
import { IconButton, Menu, MenuItem, Box, Typography, Card, CardContent, CardMedia } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from 'axios';

import AddToWeekPlannerModal from './AddToWeekPlannerModal'; // Adjust the path as necessary

const RecipeItem = ({recipe}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const open = Boolean(anchorEl);

    const placeholderImage = `${process.env.PUBLIC_URL}/images/placeholder.png`;

    const isVegan = recipe.categories.includes('Vegan');
    const isVegetarian = recipe.categories.includes('Vegetarisch') && !isVegan;

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.preventDefault();
        setAnchorEl(null);
    };
    const handleModalSubmit = async (selection) => {
        const apiUrl = process.env.REACT_APP_API_URL; // Your API URL from .env
        const token = localStorage.getItem('token'); // Assuming the token is stored with the key 'token'

        const payload = {
            recipeId: recipe._id,
            date: selection.date,
            meal: selection.meal,
        };

        try {
            const response = await axios.post(`${apiUrl}/weekplans/`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Handle success (e.g., show a confirmation message)
        } catch (error) {
            console.error("Error adding to week planner:", error.response ? error.response.data : error.message);
            // Handle error (e.g., show an error message)
        } finally {
            setModalOpen(false); // Close the modal regardless of the outcome
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleAddToWeekPlanner = () => {
        setModalOpen(true);
    };

    const truncateDescription = (description) => {
        if (!description) {
            return '';
        }
        if (description.length > 100) {
            return description.substring(0, 100) + '...';
        }
        return description;
    };

    const abbreviateTimeUnit = (unit) => {
        const unitMap = {
            Minuten: 'min',
            Stunden: 'h',
            // Add more mappings as needed
        };
        return unitMap[unit] || unit;
    };

    return (
        <Link to={`/recipes/${recipe._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Card sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px auto',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                alignItems: 'center',
                padding: 1,
                width: 'calc(100% - 40px)',
                flexDirection: { xs: 'column', sm: 'row' },
            }}>
                <CardMedia
                    component="img"
                    image={recipe.images?.[0] ? recipe.images[0].indexOf('base64') >= 0 ? recipe.images[0] : process.env.REACT_APP_IMAGE_URL + recipe.images[0] : placeholderImage}
                    alt={recipe.name || 'Placeholder'}
                    sx={{
                        width: { xs: 80, sm: 100 },
                        height: { xs: 80, sm: 100 },
                        borderRadius: '50%',
                        border: '2px solid #ccc',
                        objectFit: 'cover',
                    }}
                />
                <CardContent sx={{
                    flex: 3,
                    textAlign: { xs: 'center', sm: 'left' },
                    '&:last-child': { paddingBottom: '16px' },
                }}>
                    <Typography variant="h5" component="h3">{recipe.name}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                        {truncateDescription(recipe.description)}
                    </Typography>
                </CardContent>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: 2,
                    '& span': { marginRight: '5px' },
                    flexDirection: { xs: 'row', sm: 'column' },
                }}>
                    {/* Icons and Time */}
                    {isVegan && <span role="img" aria-label="Vegan" title="Vegan">🌱</span>}
                    {isVegetarian && <span role="img" aria-label="Vegetarisch" title="Vegetarisch">🥕</span>}
                    <span role="img" aria-label="clock">🕒 {recipe.totalTime.amount} {abbreviateTimeUnit(recipe.totalTime.unit)}</span>
                </Box>
                <IconButton aria-label="More actions" onClick={handleClick} sx={{ borderRadius: '50%', top: 1, right: 1 }}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleAddToWeekPlanner}>
                        Add to Week Planner
                    </MenuItem>
                </Menu>
            </Card>
            <AddToWeekPlannerModal open={modalOpen} onClose={handleModalClose} onSubmit={handleModalSubmit} />
        </Link>    );
};


export default RecipeItem;
