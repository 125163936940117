import React, {useEffect, useState} from 'react';
import {Box, IconButton, Menu, MenuItem, AppBar, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from 'react-router-dom';
import SearchBar from "./SearchBar";
import {useAuth} from "../context/AuthContext";

const NavigationHeader = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {user, logout} = useAuth();
    const navigate = useNavigate();
    const [logo, setLogo] = useState(`${process.env.PUBLIC_URL}/logo192.png`);

    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        // If the menu is currently open and the same target is clicked, close the menu
        if (anchorEl && anchorEl === event.currentTarget) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        setAnchorEl(null);
        navigate(path);
    };

    const handleSearch = (searchTerm) => {
        // Implement the search logic here, e.g., updating the state with search results
    };

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) { // Define the breakpoint for "smaller devices"
                setLogo(`${process.env.PUBLIC_URL}/favicon.ico`);
            } else {
                setLogo(`${process.env.PUBLIC_URL}/logo192.png`);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call it initially to set the correct logo on load

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <AppBar position="static" sx={{backgroundColor: 'transparent', boxShadow: 'none'}} elevation={0}>
            <Toolbar sx={{justifyContent: 'space-between', alignItems: 'center', padding: '0 1em'}}>
                <IconButton onClick={() => navigate('/')} sx={{height: '100%', display: 'flex', alignItems: 'center'}}>
                    <img src={logo} alt="App Logo" style={{maxHeight: '100%', height: '5vh', maxWidth: '100%'}}/>
                </IconButton>
                {user ? (
                    <>
                        <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center'}}>
                            <SearchBar onSearch={handleSearch} style={{flexGrow: 1}}/>
                        </Box>

                        {/* Menu and User Authentication Buttons */}
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="menu"
                                onClick={handleMenuClick}
                                sx={{height: 'inherit'}}>
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="burger-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                sx={{
                                    '& .MuiMenu-list': {
                                        display: 'flex',
                                        flexDirection: 'column'
                                    },
                                }}
                            >
                                <MenuItem key="Home" onClick={() => handleNavigate('/')}>Home</MenuItem>
                                <MenuItem key="Entdecken"
                                          onClick={() => handleNavigate('/entdecken')}>Entdecken</MenuItem>
                                <MenuItem key="Speisekammer"
                                          onClick={() => handleNavigate('/speisekammer')}>Speisekammer</MenuItem>
                                <MenuItem key="Wochenplan"
                                          onClick={() => handleNavigate('/wochenplan')}>Wochenplan</MenuItem>
                                <MenuItem key="Einkaufsliste"
                                          onClick={() => handleNavigate('/einkaufsliste')}>Einkaufsliste</MenuItem>
                                <MenuItem key="Meine Listen" onClick={() => handleNavigate('/listen')}>Meine
                                    Listen</MenuItem>
                                <MenuItem key="Profil" onClick={() => handleNavigate('/')}>Profil</MenuItem>
                                <MenuItem key="Freunde" onClick={() => handleNavigate('/')}>Freunde</MenuItem>
                                <MenuItem key="Admin" onClick={() => handleNavigate('/admin')}>Admin</MenuItem>
                                <MenuItem key="Ausloggen" onClick={handleLogout}>Ausloggen</MenuItem>
                            </Menu>
                        </Box>
                    </>
                ) : (
                    <IconButton onClick={() => navigate('/login')}>
                        <Typography variant="h6" component="h1" gutterBottom fontFamily={"Monotype Corsiva Italic"}
                                    fontStyle={'italic'} color={'primary'}>
                            Login
                        </Typography>
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationHeader;
