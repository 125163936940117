import React from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import axios from "axios";

const Input = styled('input')({
    display: 'none',
});

function UploadButton({onImageUpload}) {
    const token = localStorage.getItem('token'); // Assuming token is stored in local storage

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error('No file selected.');
            return;
        }
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/image`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data) {
                onImageUpload(response.data); // Assuming the server response includes the filePath
            }
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };


    return (
        <>
            <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                capture="environment" // This hints to the browser to use the camera for input
                onChange={handleImageUpload}
            />
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera/>
                </IconButton>
            </label>
        </>
    );
}

export default UploadButton;
