import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import axios from 'axios'; // Assuming you use axios for HTTP requests

const PantryContext = createContext();

export const usePantry = () => useContext(PantryContext);

export const PantryProvider = ({ children }) => {
    const [ingredients, setIngredients] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                if (!token) {
                    console.error("Token is not available. User might not be logged in.");
                    return; // Exit early if there is no token
                }

                const response = await axios.get(process.env.REACT_APP_API_URL + '/ingredients/availability', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }});
                setIngredients(response.data.data);
            } catch (error) {
                console.error("Failed to fetch ingredients", error);
            }
        };

        fetchIngredients();
    }, [token]);

    const toggleIngredientAvailability = async (ingredientId, available) => {
        if (!token) {
            console.error("Token is not available. User might not be logged in.");
            return; // Exit early if there is no token
        }

        try {
            await axios.patch(process.env.REACT_APP_API_URL + `/ingredients/${ingredientId}/availability`, { available }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }});
            setIngredients(prevIngredients =>
                prevIngredients.map(ingredient =>
                    ingredient._id === ingredientId ? { ...ingredient, available: !ingredient.available } : ingredient
                )
            );
        } catch (error) {
            console.error("Failed to update ingredient availability", error);
        }
    };

    const value = useMemo(() => ({ ingredients, toggleIngredientAvailability }), [ingredients]);

    return (
        <PantryContext.Provider value={value}>
            {children}
        </PantryContext.Provider>
    );
};
