import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import MealComponent from './MealComponent'; // Adjust the import path as necessary

const DayComponent = ({dayData, onUpdateDay, recipes}) => {
    // Convert the date string to a more readable format, e.g., "Monday, September 18"
    const formatDate = (dateString) => {
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(dateString).toLocaleDateString('de-DE', options);
    };

    const mealTypes = {
        breakfast: 'Frühstück',
        lunch: 'Mittagessen',
        dinner: 'Abendessen',
    };

    const handleUpdateMeal = (mealType, recipe, isAdd) => {
        let updatedMeals;
        if (isAdd) {
            updatedMeals = {
                ...dayData.meals,
                [mealType]: dayData.meals[mealType] ? [...dayData.meals[mealType], recipe] : [recipe],
            };
        } else {
            updatedMeals = {
                ...dayData.meals,
                [mealType]: dayData.meals[mealType].filter(id => id._id !== recipe._id),
            };
        }
        onUpdateDay(dayData.date, updatedMeals);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                    {formatDate(dayData.date)} {/* Display formatted date */}
                </Typography>
                {Object.entries(mealTypes).map(([mealType, mealName]) => (
                    <MealComponent
                        key={mealType}
                        mealType={mealName} // Use German meal names
                        meals={dayData.meals[mealType] || []} // Provide an empty array if no meals
                        allRecipes={recipes}
                        onUpdateMeal={(recipeId, isAdd) => handleUpdateMeal(mealType, recipeId, isAdd)}
                    />
                ))}
            </CardContent>
        </Card>
    );
};

export default DayComponent;
