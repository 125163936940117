import React, { useState } from 'react';
import {
    Container,
    Typography,
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import ChipInput from "./ChipInput";
import ingredientService from "../services/ingredientService";

const IngredientForm = ({onSuccess}) => {
    const initialFormState = {
        images: [''],
        name: '',
        description: '',
        nutritional_info: {
            carbohydrates: '',
            energy: '',
            fat: '',
            fat_in_dry_matter: '',
            fiber: '',
            protein: '',
            saturated_fatty_acids: '',
            sodium: '',
            sugar: '',
            volume_percent: ''
        },
        season: '',
        tags: [],
        category: '',
        icon: ''
    };
    const [ingredient, setIngredient] = useState(initialFormState);

    const setTags = (newTags) => {
        setIngredient({...ingredient, tags: newTags});
    };

    function sanitizeObject(obj) {
        // Iterate over each property in the object
        const sanitized = Object.entries(obj).reduce((acc, [key, value]) => {
            // If the value is an object and not null, recurse
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                acc[key] = sanitizeObject(value);
            } else if (Array.isArray(value)) {
                // If the value is an array, filter out empty strings
                acc[key] = value.filter(item => item !== '');
            } else {
                // For all other values, assign them as they are
                acc[key] = value;
            }
            return acc;
        }, {});

        return sanitized;
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setIngredient((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle change for nested objects and arrays
    const handleNestedChange = (e, index, field, subfield) => {
        const updateNestedField = (obj, path, value) => {
            const keys = path.split('.');
            const lastKey = keys.pop();
            const lastObj = keys.reduce((acc, key) => acc[key] = acc[key] || {}, obj);
            lastObj[lastKey] = value;
        };

        setIngredient(prevState => {
            const updatedIngredient = { ...prevState };
            if (Array.isArray(updatedIngredient[field])) {
                if (subfield) {
                    if (subfield.indexOf('.') >= 0) {
                        // For deep nested updates
                        const currentItem = updatedIngredient[field][index];
                        updateNestedField(currentItem, subfield, e.target.value);
                    } else {
                        // For single level nested updates within arrays
                        updatedIngredient[field][index][subfield] = e.target.value;
                    }
                } else {
                    // Direct update on the array item
                    updatedIngredient[field][index] = e.target.value;
                }
            } else {
                // This handles non-array fields, might not be necessary based on your data structure
                if (subfield) {
                    updateNestedField(updatedIngredient[field], subfield, e.target.value);
                } else {
                    updatedIngredient[field] = e.target.value;
                }
            }
            return updatedIngredient;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const sanitizedIngredient = sanitizeObject(ingredient);
            // Adjust the endpoint as necessary
            const response = await ingredientService.addIngredient(sanitizedIngredient);
            setIngredient(initialFormState);
            if (typeof onSuccess === 'function') {
                onSuccess(response.data);
            }
        } catch (error) {
            console.error('Error submitting ingredient:', error.response.data);
            // Handle errors
        }
    };

    const handleFormKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            // Optionally, trigger your own custom logic here
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" style={{ marginBottom: '20px' }}>Add New Ingredient</Typography>
            <form onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
                <Grid container spacing={2}>
                    {/* Name Field */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={ingredient.name}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    {/* Description Field */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={ingredient.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Season"
                            name="season"
                            value={ingredient.season}
                            onChange={handleChange}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Category"
                            name="category"
                            value={ingredient.category}
                            onChange={handleChange}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Icon"
                            name="icon"
                            value={ingredient.icon}
                            onChange={handleChange}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Nutritional Info</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="kJ/kcal"
                            name="energy"
                            value={ingredient.nutritional_info.energy}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'energy')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Fat"
                            name="fat"
                            value={ingredient.nutritional_info.fat}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'fat')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="GFS"
                            name="saturated_fatty_acids"
                            value={ingredient.nutritional_info.saturated_fatty_acids}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'saturated_fatty_acids')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Sodium"
                            name="sodium"
                            value={ingredient.nutritional_info.sodium}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'sodium')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="KH"
                            name="carbohydrates"
                            value={ingredient.nutritional_info.carbohydrates}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'carbohydrates')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Fiber"
                            name="fiber"
                            value={ingredient.nutritional_info.fiber}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'fiber')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Sugar"
                            name="sugar"
                            value={ingredient.nutritional_info.sugar}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'sugar')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Protein"
                            name="protein"
                            value={ingredient.nutritional_info.protein}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'protein')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="F. i. d. Tr."
                            name="fat_in_dry_matter"
                            value={ingredient.nutritional_info.fat_in_dry_matter}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'fat_in_dry_matter')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Volume Percent"
                            name="volume_percent"
                            value={ingredient.nutritional_info.volume_percent}
                            onChange={(e) => handleNestedChange(e, null, 'nutritional_info', 'volume_percent')}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Tags</Typography>
                    </Grid>

                    <Grid item xs={6}>

                        <Grid item xs={6}>
                            <Typography>Tags</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <ChipInput
                                entities={ingredient.tags}
                                setEntities={setTags}
                                label={"Tags"}
                            />
                        </Grid>
                    </Grid>

                    {/* Additional fields for nutritional_info, season, tags, category, icon */}
                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default IngredientForm;
