import axios from 'axios';

const baseUrl = '/api/weekplans'; // Adjust if your base URL is different
const getToken = () => localStorage.getItem('token');


export const fetchCurrentWeekPlan = async () => {
    try {
        const response = await axios.get(`${baseUrl}/current`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching week plan:', error);
        throw error;
    }
};

export const fetchWeekPlanByDate = async (date) => {
    try {
        const response = await axios.get(`${baseUrl}/byDate`, {
            params: { date }, // Assuming the API accepts a 'date' query parameter
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching week plan by date:', error);
        throw error;
    }
};

export const updateWeekPlan = async (weekPlanId, weekPlan) => {
    // Clone the weekPlan to avoid mutating the original object
    const transformedWeekPlan = JSON.parse(JSON.stringify(weekPlan));

    // Iterate over each day in the weekPlan
    transformedWeekPlan.days.forEach(day => {
        // For each meal type in a day, replace the recipe objects with their _ids
        ['breakfast', 'lunch', 'dinner'].forEach(mealType => {
            if (day.meals[mealType]) {
                day.meals[mealType] = day.meals[mealType].map(recipe => recipe._id || recipe);
            }
        });
    });

    try {
        await axios.put(`${baseUrl}/${weekPlanId}`, transformedWeekPlan, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    } catch (error) {
        console.error('Error updating week plan:', error);
        throw error;
    }
};
