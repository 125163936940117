import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RecipeList from './RecipeList';
import CircularProgress from '@mui/material/CircularProgress';

const SearchResultsPage = () => {
    const [query, setQuery] = useState();
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryParam = queryParams.get('query');
        if (queryParam) {
            const queryLowercased = queryParam.toLowerCase();
            setQuery(queryLowercased);
        }
        setLoading(false);
    }, [location]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div>
            <h2>Search Results</h2>
            {query ? <RecipeList initialSearchQuery={query}/> : <p>Please enter a search query.</p>}
        </div>
    );
};

export default SearchResultsPage;
