import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Ensure you have your API base URL here

// Creating axios instance
const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Retrieve the token at the time of request
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const recipeSourceService = {
    // Fetch all recipe sources with optional pagination and filtering
    getAllSources: async ({ page, itemsPerPage, type, searchTerm } = {}) => {
        let queryString = '?';
        if (page !== undefined) {
            queryString += `page=${page}&`;
        }
        if (itemsPerPage) {
            queryString += `itemsPerPage=${itemsPerPage}&`;
        }
        if (type) {
            queryString += `type=${encodeURIComponent(type)}&`;
        }
        if (searchTerm) {
            queryString += `search=${encodeURIComponent(searchTerm)}&`;
        }
        // Remove the last '&' or '?' from the queryString if necessary
        queryString = queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
        queryString = queryString === '?' ? '' : queryString; // If queryString is only '?', set it to empty

        try {
            const response = await api.get(`/sources${queryString}`);
            return response.data; // Assuming the API returns the list of sources along with any other pagination metadata if necessary
        } catch (error) {
            throw error; // Propagate the error to be handled by the calling component
        }
    },

    // Add a new recipe source
    createSource: async (sourceData) => {
        try {
            const response = await api.post('/sources', sourceData);
            return response.data; // Assuming the API returns the created source object
        } catch (error) {
            throw error; // Propagate the error to be handled by the calling component
        }
    },

    // Get a recipe source by its ID
    getSourceById: async (sourceId) => {
        try {
            const response = await api.get(`/sources/${sourceId}`);
            return response.data; // Assuming the API returns the source object
        } catch (error) {
            throw error;
        }
    },

    // Update a recipe source by its ID
    updateSourceById: async (sourceId, sourceData) => {
        try {
            const response = await api.patch(`/sources/${sourceId}`, sourceData);
            return response.data; // Assuming the API returns the updated source object
        } catch (error) {
            throw error;
        }
    },

    // Delete a recipe source by its ID
    deleteSourceById: async (sourceId) => {
        try {
            const response = await api.delete(`/sources/${sourceId}`);
            return response.data; // Assuming the API returns some indication of deletion
        } catch (error) {
            throw error;
        }
    },
};

export default recipeSourceService;
