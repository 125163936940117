import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    Typography,
    IconButton,
    Box,
    Chip,
    ListItem,
    Avatar,
    ListItemText,
    List,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Modal,
    Button,
    Link
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Icon for "More actions"
import AddToWeekPlannerModal from './AddToWeekPlannerModal'; // Import the modal component
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import WbSunnyIcon from '@mui/icons-material/WbSunny'; // Summer
import ParkIcon from '@mui/icons-material/Park'; // Fall/Autumn (as an alternative)
import AcUnitIcon from '@mui/icons-material/AcUnit'; // Winter
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Example for "Ganzjährig"
import RatingsList from './RatingsList';
import RatingForm from "./RatingForm";
import { useAuth } from '../context/AuthContext';
import { Menu, MenuItem } from "@material-ui/core";
import axios from "axios";

const RecipeDetails = () => {
    const { id } = useParams();
    const [anchorEl, setAnchorEl] = useState(null); // For the "More Actions" menu
    const [recipe, setRecipe] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const placeholderImage = `${process.env.PUBLIC_URL}/images/placeholder.png`;
    const [ratings, setRatings] = useState([]);
    const [existingRating, setExistingRating] = useState(null);
    const [showRatingModal, setShowRatingModal] = useState(false);
    const token = localStorage.getItem('token');
    const [showAddToPlannerModal, setShowAddToPlannerModal] = useState(false);

    const handleOpenAddToPlannerModal = () => setShowAddToPlannerModal(true);
    const handleCloseAddToPlannerModal = () => setShowAddToPlannerModal(false);
    const open = Boolean(anchorEl);

    const carouselRef = useRef(null);
    const [arrowOffset] = useState(0);

    const { user } = useAuth();
    const userId = user ? user._id : null;

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAddToPlannerSubmit = async (selection) => {
        const apiUrl = process.env.REACT_APP_API_URL; // Your API URL from .env
        const token = localStorage.getItem('token'); // Assuming the token is stored with the key 'token'

        const payload = {
            recipeId: recipe._id,
            date: selection.date,
            meal: selection.meal,
        };

        try {
            const response = await axios.post(`${apiUrl}/weekplans/`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Handle success (e.g., show a confirmation message)
        } catch (error) {
            console.error("Error adding to week planner:", error.response ? error.response.data : error.message);
            // Handle error (e.g., show an error message)
        } finally {
            handleCloseAddToPlannerModal(); // Close the modal regardless of the outcome
        }

    };
    const fetchRecipe = async () => {
        try {
            const recipeResponse = await fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!recipeResponse.ok) {
                throw new Error('Failed to fetch data');
            }
            const recipeData = await recipeResponse.json();

            setRecipe(recipeData);

        } catch (err) {
            setError(err.message);
        }
    }
    const fetchRating = async () => {
        try {
            const ratingsResponse = await fetch(`${process.env.REACT_APP_API_URL}/ratings/recipe/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!ratingsResponse.ok) {
                throw new Error('Failed to fetch data');
            }

            const ratingsData = await ratingsResponse.json();
            setRatings(ratingsData);
        } catch (err) {
            setError(err.message);
        }
    }
    const fetchExistingRating = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/ratings/user/${userId}/recipe/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Could not fetch user rating');
            const data = await response.json();
            setExistingRating(data);
        } catch (error) {
            console.error('Failed to fetch user rating:', error);
        }
    };
    const processIngredients = (ingredients, groups) => {
        // Initialize the groupedIngredients object with keys from the groups array
        const groupedIngredients = groups.reduce((acc, group) => {
            acc[group] = [];
            return acc;
        }, {});

        // Default group for ungrouped ingredients
        groupedIngredients["Ungrouped"] = [];

        ingredients.forEach((ingredient) => {
            const group = ingredient.group || "Ungrouped";
            if (groupedIngredients.hasOwnProperty(group)) {
                groupedIngredients[group].push(ingredient);
            } else {
                groupedIngredients["Ungrouped"].push(ingredient);
            }
        });

        // Remove the "Ungrouped" key if it's empty
        if (groupedIngredients["Ungrouped"].length === 0) {
            delete groupedIngredients["Ungrouped"];
        }

        return groupedIngredients;
    };

    useEffect(() => {
        const fetchRecipeAndRatings = async () => {
            setIsLoading(true);
            setError(null);
            try {
                await fetchRecipe();
                await fetchRating();
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchRecipeAndRatings();

        if (userId) {
            fetchExistingRating();
        }
    }, [id, userId, token]);

    const handleRatingSubmit = async () => {
        await fetchRating();
        await fetchExistingRating();
        return handleCloseRatingModal();
    };
    const handleOpenRatingModal = () => {
        setShowRatingModal(true);
    };
    const handleCloseRatingModal = () => {
        setShowRatingModal(false);
    };

    if (isLoading) return <Box>Loading...</Box>;
    if (error) return <Box>Error: {error}</Box>;
    if (!recipe) return <Box>Recipe not found.</Box>;

    const SeasonIcon = ({ season }) => {
        switch (season) {
            case 'Frühling':
                return <LocalFloristIcon sx={{ color: "#8f25b2", mr: 2 }} />;
            case 'Sommer':
                return <WbSunnyIcon sx={{ color: "#fcca37", mr: 2 }} />;
            case 'Herbst':
                return <ParkIcon sx={{ color: "#843d09", mr: 2 }} />;
            case 'Winter':
                return <AcUnitIcon sx={{ color: "#c7c6c6", mr: 2 }} />;
            case 'Ganzjährig':
                return <CalendarTodayIcon />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            {/* Recipe Name and Categories */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {recipe.name}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    flexWrap: 'wrap'
                }}>
                    <Tooltip title={`${recipe.season}`} placement="top">
                        <span>
                            <SeasonIcon season={recipe.season} />
                        </span>
                    </Tooltip>
                    <Typography variant="subtitle1" component="span" sx={{ mr: 2 }}>
                        {recipe.categories.join(', ')}
                    </Typography>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{ borderRadius: '50%' }}
                    > <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleOpenAddToPlannerModal();
                            handleClose();
                        }}>
                            Add to Week Planner
                        </MenuItem>
                    </Menu>
                    <IconButton aria-label="Add to favorites" sx={{ borderRadius: '50%' }}>
                        <FavoriteBorderIcon />
                    </IconButton>
                </Box>
            </Box>

            <AddToWeekPlannerModal
                open={showAddToPlannerModal}
                onClose={handleCloseAddToPlannerModal}
                onSubmit={handleAddToPlannerSubmit}
            />
            {/* Carousel for Images */}
            <div>
                <div ref={carouselRef} style={{ maxWidth: '600px', margin: 'auto' }}>
                    <Carousel showArrows={true} // Explicitly show navigation arrows
                        showThumbs={false} // Optionally, hide thumbnails
                        showStatus={false} // Optionally, hide the status indicator
                        dynamicHeight={false} // Maintain a consistent height
                        axis="horizontal" // Ensuring horizontal scrolling, if needed
                        style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}
                    >
                        {recipe.images.length > 0 ? (
                            recipe.images.map((image, index) => (
                                <div key={index} style={{ textAlign: 'left' }}>
                                    <img
                                        src={image.indexOf('base64') >= 0 ? image : process.env.REACT_APP_IMAGE_URL + image}
                                        alt={`Recipe ${index}`}
                                        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        className="carousel-image" />
                                </div>
                            ))
                        ) : (
                            <div>
                                <img src={placeholderImage} alt="Placeholder"
                                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                    className="carousel-image" />
                            </div>
                        )}
                    </Carousel>
                </div>
                <style>
                    {`
                       .carousel .control-arrow {
                            opacity: 1 !important;
                            background: rgba(0, 0, 0, 0.5) !important;
                        }
                        .carousel .control-prev.control-arrow {
                            left: ${arrowOffset}px !important;
                        }
                        .carousel .control-next.control-arrow {
                            right: ${arrowOffset}px !important;
                        }
                    `}
                </style>

            </div>
            <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                {recipe.description}
            </Typography>
            {/* Time Indication and Tags */}
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                {recipe.totalTime.amount && (
                    <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                        🕒 {recipe.totalTime.amount} {recipe.totalTime.unit}
                    </Typography>
                )}
                {recipe.tags.map((tag, index) => (
                    <Chip key={index} label={tag} variant="outlined" />
                ))}
            </Box>

            <Box sx={{ mt: 2, mb: 2 }}>
                <Grid container spacing={2}>
                    {/* Ingredients List */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Zutaten
                        </Typography>
                        {recipe.servings && recipe.servings !== '' && (
                            <Typography variant="subtitle2" component="p">
                                <strong>Ergibt:</strong> {recipe.servings}
                            </Typography>
                        )}
                        {Object.entries(processIngredients(recipe.ingredients, recipe.groups)).map(([group, items], groupIndex) => (
                            <React.Fragment key={groupIndex}>
                                {group !== "Ungrouped" && <Typography variant="overline">{group}</Typography>}
                                <List dense={true}>
                                    {items.map((ingredient, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        {ingredient.quantity.amount} {ingredient.quantity.unit}{' '}
                                                        {ingredient.ingredient ?
                                                            ingredient.ingredient.name :
                                                            ingredient.recipeRef ?
                                                                <Link href={"/recipes/" + ingredient.recipeRef._id} color="primary">
                                                                    {ingredient.recipeRef.name}
                                                                </Link>
                                                                : ''}
                                                        {ingredient.alternatives && ingredient.alternatives.length > 0 && (
                                                            <>
                                                                {' oder '}
                                                                {ingredient.alternatives.map((alt, altIndex) => (
                                                                    <span key={altIndex}>
                                                                        {altIndex > 0 && ', '}
                                                                        {alt.quantity.amount} {alt.quantity.unit}{' '}
                                                                        {alt.ingredient ?
                                                                            alt.ingredient.name :
                                                                            alt.recipeRef ?
                                                                                <Link href={"/recipes/" + alt.recipeRef._id} color="primary">
                                                                                    {alt.recipeRef.name}
                                                                                </Link>
                                                                                : ''
                                                                        }
                                                                    </span>
                                                                ))}
                                                            </>
                                                        )}
                                                        {ingredient.note ? ` (${ingredient.note})` : ''}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </React.Fragment>
                        ))}
                    </Grid>
                    {/* Instructions */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Arbeitsschritte
                        </Typography>
                        <Box sx={{ pl: 2, pr: 2 }}>
                            {recipe.instructions.map((instruction, index) => (
                                <Paper variant="outlined" sx={{ mb: 2, display: 'flex', alignItems: 'center', p: 2 }}
                                    key={index}>
                                    <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>{index + 1}</Avatar>
                                    <Typography variant="body1">{instruction}</Typography>
                                </Paper>
                            ))}
                        </Box>
                        {recipe.additionalNotes && recipe.additionalNotes.length > 0 && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h6">Weitere Informationen & Tipps:</Typography>
                                <List>
                                    {recipe.additionalNotes.map((note, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={note} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                {(recipe.nutritionPerPortion.carbohydrates || recipe.nutritionPerPortion.fiber || recipe.nutritionPerPortion.energy) && (
                    <Grid item xs={12} sm={6}>
                        <TableContainer component={Paper} sx={{ mt: 2, overflowX: "auto" }}>
                            <Table sx={{ minWidth: "100%" }} aria-label="nutrition table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Nährwerte pro Portion ({recipe.portion})</strong></TableCell>
                                        <TableCell align="right"> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recipe.nutritionPerPortion.carbohydrates && (
                                        <TableRow>
                                            <TableCell component="th" scope="row">Kohlenhydrate</TableCell>
                                            <TableCell
                                                align="right">{recipe.nutritionPerPortion.carbohydrates}</TableCell>
                                        </TableRow>
                                    )}
                                    {recipe.nutritionPerPortion.fiber && (
                                        <TableRow>
                                            <TableCell component="th" scope="row">Ballaststoffe</TableCell>
                                            <TableCell align="right">{recipe.nutritionPerPortion.fiber}</TableCell>
                                        </TableRow>
                                    )}
                                    {recipe.nutritionPerPortion.energy && (
                                        <TableRow>
                                            <TableCell component="th" scope="row">Kilojoule / Kilokalorien</TableCell>
                                            <TableCell align="right">{recipe.nutritionPerPortion.energy}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
                {(recipe.prepTime || recipe.cookTime || recipe.restingTime || recipe.totalTime) && (
                    <Grid item xs={12} sm={6}>
                        <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
                            <Table sx={{ minWidth: "100%" }} aria-label="times table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Zeit</strong></TableCell>
                                        <TableCell align="right"><strong>Dauer</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recipe.prepTime && recipe.prepTime.amount && (
                                        <TableRow>
                                            <TableCell component="th" scope="row">Vorbereitungszeit</TableCell>
                                            <TableCell
                                                align="right">{recipe.prepTime.amount} {recipe.prepTime.unit}</TableCell>
                                        </TableRow>
                                    )}
                                    {recipe.cookTime && recipe.cookTime.amount && (

                                        <TableRow>
                                            <TableCell component="th" scope="row">Koch- / Backzeit</TableCell>
                                            <TableCell
                                                align="right">{recipe.cookTime.amount} {recipe.cookTime.unit}</TableCell>
                                        </TableRow>
                                    )}
                                    {recipe.restingTime && recipe.restingTime.amount && (
                                        <TableRow>
                                            <TableCell component="th" scope="row">Ruhezeit</TableCell>
                                            <TableCell
                                                align="right">{recipe.restingTime.amount} {recipe.restingTime.unit}</TableCell>
                                        </TableRow>
                                    )}
                                    {recipe.totalTime && recipe.totalTime.amount && (
                                        <TableRow>
                                            <TableCell component="th"
                                                scope="row"><strong>Gesamtzeit</strong></TableCell>
                                            <TableCell
                                                align="right"><strong>{recipe.totalTime.amount} {recipe.totalTime.unit}</strong></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
            {/* Ratings and Comments */}
            <Button variant="contained" onClick={handleOpenRatingModal}>
                {existingRating ? 'Edit Rating' : 'Add Rating'}
            </Button>
            <Modal
                open={showRatingModal}
                onClose={handleCloseRatingModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {existingRating ? 'Edit Your Rating' : 'Rate This Recipe'}
                    </Typography>
                    <RatingForm
                        recipeId={id}
                        existingRating={existingRating}
                        onRatingSubmit={handleRatingSubmit}
                    />
                </Box>
            </Modal>
            <RatingsList ratings={ratings} />
        </Box>
    );
};

export default RecipeDetails;
