import React from 'react';
import RecipeList from './RecipeList'; // Assuming you have this component

const DiscoverPage = () => {
    return (
        <div>
            <RecipeList/>
        </div>
    );
};

export default DiscoverPage;
