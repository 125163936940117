import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

const AddToWeekPlannerModal = ({ open, onClose, onSubmit }) => {
    const [date, setDate] = useState('');
    const [meal, setMeal] = useState('');

    const handleSubmit = () => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(0, 0, 0, 0); // Set the time part to midnight local time
        const isoString = adjustedDate.toISOString(); // Converts to ISO string format

        onSubmit({ date: isoString, meal });
        onClose(); // Close the modal after submission
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add to Week Planner</DialogTitle>
            <DialogContent>
                <form>
                    <TextField
                        label="Date"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel>Meal</InputLabel>
                        <Select
                            value={meal}
                            label="Meal"
                            onChange={(e) => setMeal(e.target.value)}
                        >
                            <MenuItem value="breakfast">Breakfast</MenuItem>
                            <MenuItem value="lunch">Lunch</MenuItem>
                            <MenuItem value="dinner">Dinner</MenuItem>
                        </Select>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddToWeekPlannerModal;
