import React, {useState, useEffect, useRef} from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, TextField, ListItemIcon, ListItemText, Grid } from '@mui/material';
import recipeSourceService from '../services/recipeSourceService';
import BookIcon from '@mui/icons-material/Book';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import WebIcon from '@mui/icons-material/Web';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const RecipeSourceSelect = ({source, onChange}) => {
    const [sources, setSources] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sourceType, setSourceType] = useState('');
    const loader = useRef(null);

    const sourceTypeIcons = {
        book: <BookIcon />,
        magazine: <ImportContactsIcon />,
        online: <WebIcon />,
        other: <HelpOutlineIcon />,
    };

    useEffect(() => {
        const fetchSources = async () => {
            setLoading(true);
            try {
                const fetchedData = await recipeSourceService.getAllSources({
                    page: page,
                    itemsPerPage: 10,
                    type: sourceType,
                    searchTerm: searchTerm
                });
                const {count, sources: newSources} = fetchedData;
                if (page === 1) {
                    setSources(newSources);
                } else {
                    setSources(prev => [...prev, ...newSources]);
                }
                setHasMore(sources.length < count);
            } catch (error) {
                console.error('Failed to fetch sources:', error);
            }
            setLoading(false);
        };

        fetchSources();
    }, [page]);

    useEffect(() => {
        setPage(1);
        setSources([]);
    }, [sourceType, searchTerm]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entities) => {
            const target = entities[0];
            if (target.isIntersecting && hasMore && !loading) {
                setPage((prev) => prev + 1);
            }
        }, options);

        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        };
    }, [loader, hasMore, loading]);

    return (
        <Box>
            <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={9}>
                    <TextField
                        fullWidth
                        label="Search by Name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={sourceType}
                            label="Type"
                            onChange={(e) => setSourceType(e.target.value)}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="book">Book</MenuItem>
                            <MenuItem value="magazine">Magazine</MenuItem>
                            <MenuItem value="online">Online</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
                <InputLabel id="recipe-source-select-label">Source</InputLabel>
                <Select
                    labelId="recipe-source-select-label"
                    id="recipe-source-select"
                    value={source}
                    label="Source"
                    onChange={onChange}
                >
                    {sources.map((src) => (
                        <MenuItem key={src._id} value={src._id} sx={{ display: 'flex', alignItems: 'center' }}>
                            <ListItemIcon sx={{ minWidth: '30px' }}> {/* Adjust minWidth as needed */}
                                {sourceTypeIcons[src.type] || sourceTypeIcons.other}
                            </ListItemIcon>
                            <ListItemText primary={src.title} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} />
                        </MenuItem>
                    ))}
                    {loading && (
                        <MenuItem disabled>
                            <CircularProgress size={24} />
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <div ref={loader} />
        </Box>
    );
};

export default RecipeSourceSelect;