import axios from 'axios';

const BASE_URL = '/api/recipes'; // Adjust if your base URL is different
const getToken = () => localStorage.getItem('token');

const headers = () => ({
    Authorization: `Bearer ${getToken()}`,
});

export const fetchRecipeById = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/${id}`, { headers: headers() });
        return response.data;
    } catch (error) {
        console.error('Error fetching recipe by ID:', error);
        throw error;
    }
};

export const fetchRecipesByIds = async (ids) => {
    try {
        const recipes = await Promise.all(ids.map(id => fetchRecipeById(id)));
        return recipes;
    } catch (error) {
        console.error('Error fetching recipes:', error);
        throw error;
    }
};

export const fetchAllRecipes = async (queryParams) => {
    const token = localStorage.getItem('token'); // Assuming token is stored in local storage

        let queryString = '?';
        if (queryParams.page !== undefined) {
            queryString += `page=${queryParams.page}&`;
        }

        if (queryParams.searchTerm) {
            queryString += `searchQuery=${encodeURIComponent(queryParams.searchTerm)}&`;
        }
        if (queryParams.cuisine) {
            queryString += `cuisine=${encodeURIComponent(queryParams.cuisine)}&`;
        }
        if (queryParams.categories) {
            queryString += `categories=${encodeURIComponent(queryParams.categories)}&`;
        }
        if (queryParams.usePantryFilter) {
            queryString += `usePantryFilter=${encodeURIComponent(queryParams.usePantryFilter)}&`;
        }
        if (queryParams.matchThreshold) {
            queryString += `matchThreshold=${encodeURIComponent(queryParams.matchThreshold)}&`;
        }
        if (queryParams.nutrition) {
            queryString += `nutrition=${encodeURIComponent(queryParams.nutrition)}&`;
        }
        if (queryParams.sortBy) {
            queryString += `sort=${encodeURIComponent(queryParams.sortBy)}&`;
        }
        if (queryParams.limit) {
            queryString += `limit=${queryParams.limit}&`;
        }
        // Remove the last '&' or '?' from the queryString if necessary
        queryString = queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
        queryString = queryString === '?' ? '' : queryString; // If queryString is only '?', set it to empty

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/recipes${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error('Error fetching recipes');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

// You can add more recipe-related API calls here (e.g., create, update, delete)
