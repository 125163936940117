import React, { useEffect, useMemo, useRef, useState } from 'react';
import CategorySection from './CategorySection';
import ingredientService from "../services/ingredientService";
import CircularProgress from '@mui/material/CircularProgress'; // Make sure to import CircularProgress
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAuth } from "../context/AuthContext";

const PantryPage = () => {
    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(false); // Added loading state
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const { socket } = useAuth();
    const scrollRef = useRef(null); // Ref to the InfiniteScroll component

    useEffect(() => {
        if (!socket) return;

        const handleAvailabilityChange = ({ ingredientId, available }) => {
            setIngredients(prevIngredients =>
                prevIngredients.map(ingredient =>
                    ingredient._id === ingredientId ? { ...ingredient, available } : ingredient
                )
            );
        };

        // Listen for 'ingredient_availability_changed' event
        socket.on('ingredient_availability_changed', handleAvailabilityChange);

        return () => {
            if (socket) {
                socket.off('ingredient_availability_changed', handleAvailabilityChange);
            }
        };
    }, [socket]); // Run only on component mount

    const fetchMoreIngredients = async () => {
        if (!hasMore || loading) return;

        setLoading(true);

        try {
            // Assuming the backend expects 'page' and 'categoriesPerPage' as query parameters
            const data = await ingredientService.getUserIngredientsWithAvailability({
                page
            });

            if (data.data && data.data.length > 0) {
                setIngredients(prev => [...prev, ...data.data]);
                setPage(prevPage => {
                    const nextPage = prevPage + 1;
                    return nextPage;
                });
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Failed to fetch ingredients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const checkIfScrollNeeded = () => {
            if (scrollRef.current && scrollRef.current.clientHeight < window.innerHeight && hasMore && !loading) {
                fetchMoreIngredients();
            }
        };

        // Run after the DOM updates
        setTimeout(checkIfScrollNeeded, 100);

        // Also check on window resize
        window.addEventListener('resize', checkIfScrollNeeded);
        return () => window.removeEventListener('resize', checkIfScrollNeeded);
    }, [ingredients, hasMore, loading]);

    // Group ingredients by category, memoized
    const categories = useMemo(() => ingredients.reduce((acc, ingredient) => {
        const { category } = ingredient;
        acc[category] = acc[category] || [];
        acc[category].push(ingredient);
        return acc;
    }, {}), [ingredients]);

    if (!socket) {
        return <CircularProgress />;
    }
    
    return (
        <div ref={scrollRef}> {/* Attach ref to the container */}
            <InfiniteScroll
                dataLength={Object.keys(categories).length}
                next={fetchMoreIngredients}
                hasMore={hasMore}
                loader={<CircularProgress />}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>You have seen all categories</b>
                    </p>
                }
            >
                {Object.entries(categories).map(([category, ingredients]) => (
                    <CategorySection key={category} category={category} ingredients={ingredients} />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default PantryPage;
