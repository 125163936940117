import React, { createContext, useContext, useState, useEffect } from 'react';
import * as authService from '../services/authService';
import io from 'socket.io-client';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Check if there's a user and token stored and initialize socket
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser?.token) {
            initializeSocket(storedUser.token);
        }
    }, []);

    const initializeSocket = (token) => {
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            query: { token }
        });
        setSocket(newSocket);
    };

    const login = async (userData) => {
        const data = await authService.login(userData);
        setUser(data.data.user);
        const token = data.data.user.token;
        if (token) {
            initializeSocket(token);
        }
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setUser(null);
        if (socket) {
            socket.disconnect();
        }
    };

    const hasRole = (role) => {
        return user && user.role === role;
    };

    const value = {
        user,
        login,
        logout,
        hasRole,
        socket
    };


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
