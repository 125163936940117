import React from 'react';
import {Navigate, Route, useLocation} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export const ProtectedRoute = ({ element: Element }) => {
    const { user } = useAuth();

    return user ? Element : <Navigate to="/login" replace />;
};

export const RoleBasedRoute = ({ children, roles }) => {
    const {user} = useAuth();
    const location = useLocation(); // This hook is used to access the current location

    if (!user || !roles.includes(user.role)) {
        // Redirect to an unauthorized page if the user doesn't have the required role
        return <Navigate to="/unauthorized" replace state={{from: location}}/>;
    }

    return children;
}