import React from 'react';
import { List, Typography } from '@mui/material';
import RecipeCollectionListItem from "./RecipeCollectionListItem";

const RecipeCollectionList = ({ collections, onDelete, onView, onEdit }) => {
    if (collections.length === 0) {
        return <Typography variant="subtitle1">Keine Listen vorhanden.</Typography>;
    }

    return (
        <List>
            {collections.map((collection) => (
                <RecipeCollectionListItem
                    key={collection._id}
                    collection={collection}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            ))}
        </List>
    );
};

export default RecipeCollectionList;
