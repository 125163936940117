import React, { useState, useEffect, useRef, useCallback } from "react";
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Modal, Box, Checkbox, Typography, Switch } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import IngredientForm from './IngredientForm';
import ingredientService from "../services/ingredientService";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import EggIcon from '@mui/icons-material/Egg';
import BookIcon from '@mui/icons-material/Book';
import { fetchAllRecipes } from "../services/recipeService";

const RecipeIngredientSelect = ({
    index,
    ingredient,
    onNestedChange,
    handleRemoveField,
    handleAddField,
    groups,
    handleAddAlternative,
    isAlternative,
    altIndex
}) => {
    const [filterText, setFilterText] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [showIngredientForm, setShowIngredientForm] = useState(false);
    const [isRecipeMode, setIsRecipeMode] = useState(false);  // false for ingredients, true for recipes
    const observer = useRef();

    const categoryIcons = {
        'Alkoholische Getränke': 'drink.png',
        'Gemüse': 'vegetable.png',
        'Gewürze': 'spice.png',
        'Fisch': 'fish.png',
        'Fleisch': 'meat.png',
        'Käse': 'cheese.png',
        'Kräuter': 'herb.png',
        'Milchprodukte': 'dairy.png',
        'Obst': 'fruit.png',
        'default': 'default.png'
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white', // Ensure the background is not transparent
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    };

    const fetchIngredients = useCallback(async (page = 1, searchTerm = '') => {
        try {
            const params = { page, searchTerm, sortBy: 'name', limit: 20 };
            const data = await ingredientService.getAllIngredients(params);
            setHasMore(data.data.length > 0);
            if (page === 1) {
                setIngredients(data.data);
            } else {
                setIngredients(prev => [...prev, ...data.data]);
            }
            setCurrentPage(page);
        } catch (error) {
            console.error('Failed to fetch ingredients:', error);
        }
    }, []);

    const fetchRecipes = useCallback(async (page = 1, searchTerm = '') => {
        try {
            const params = { page, searchTerm, sortBy: 'name', limit: 10 };
            const data = await fetchAllRecipes(params);
            setHasMore(data.recipes.length > 0);
            if (page === 1) {
                setIngredients(data.recipes);
            } else {
                setIngredients(prev => [...prev, ...data.recipes]);
            }
            setCurrentPage(page);
        } catch (error) {
            console.error('Failed to fetch recipes:', error);
        }
    }, []);

    const lastIngredientElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            console.log('Triggered?');
            if (entries[0].isIntersecting && hasMore) {
                if (isRecipeMode) {
                    fetchRecipes(currentPage + 1, filterText);
                } else {
                    fetchIngredients(currentPage + 1, filterText);
                }
            }
        });
        if (node) observer.current.observe(node);
    }, [currentPage, hasMore, filterText, fetchIngredients, fetchRecipes]);


    useEffect(() => {
        if (isRecipeMode) {
            fetchRecipes(1, filterText);
        } else {
            fetchIngredients(1, filterText);
        }
    }, [filterText, isRecipeMode, fetchIngredients, fetchRecipes]);

    // Debounced fetch for filtering
    const handleSelectChange = (e) => {
        if (e.target.value === "add-new") {
            setShowIngredientForm(true);
        } else {
            if (!isAlternative) {
                if (isRecipeMode) {
                    onNestedChange({ target: { value: e.target.value } }, index, 'ingredients', 'recipeRef');
                    onNestedChange({ target: { value: null } }, index, 'ingredients', 'ingredient'); // Clearing the ingredient field
                } else {
                    onNestedChange({ target: { value: e.target.value } }, index, 'ingredients', 'ingredient');
                    onNestedChange({ target: { value: null } }, index, 'ingredients', 'recipeRef'); // Clearing the recipeRef field
                }
            } else {
                if (isRecipeMode) {
                    onNestedChange({ target: { value: e.target.value } }, index, altIndex, 'recipeRef');
                    onNestedChange({ target: { value: null } }, index, altIndex, 'ingredient'); // Clearing the ingredient field
                } else {
                    onNestedChange({ target: { value: e.target.value } }, index, altIndex, 'ingredient');
                    onNestedChange({ target: { value: null } }, index, altIndex, 'recipeRef'); // Clearing the recipeRef field
                }
            }
        }
    };

    const handleIngredientAdded = async (newIngredient) => {
        setShowIngredientForm(false);
        // Refresh the list to include the newly added ingredient
        fetchIngredients(1, filterText);
        // Optionally set the new ingredient as selected if needed
    };

    useEffect(() => {
        if ((ingredient.name || ingredient.name === '') && ingredient.name !== filterText) {
            setFilterText(ingredient.name);
        }
    }, [ingredient.name]);

    return <>
        <React.Fragment key={index}>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="Filter"
                    value={ingredient.name}
                    onChange={(e) => {
                        onNestedChange(e, index, 'ingredients', 'name');
                        setFilterText(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel>{isRecipeMode ? 'Recipe' : 'Ingredient'}</InputLabel>
                    <Select
                        value={ingredient.ingredient || ingredient.recipeRef}
                        displayEmpty
                        onChange={handleSelectChange}
                    >
                        {!isRecipeMode && (
                            <MenuItem value="add-new">+ Create New</MenuItem>
                        )}
                        {!isRecipeMode && ingredients.map((ing, idx) => {
                            const iconSrc = ing.icon ?
                                process.env.REACT_APP_IMAGE_URL + ing.icon :
                                `${process.env.PUBLIC_URL}/images/` + (categoryIcons[ing.category] || categoryIcons['default']);

                            return (
                                <MenuItem key={ing._id} value={ing._id} ref={ingredients.length === idx + 1 ? lastIngredientElementRef : null}>
                                    <img src={iconSrc} alt={ing.category} style={{ marginRight: 10, width: '1.5em', height: '1.5em', objectFit: 'contain' }} />
                                    {ing.name}
                                </MenuItem>
                            );
                        })}
                        {isRecipeMode && ingredients.map((recipe, idx) => (
                            <MenuItem key={recipe._id} value={recipe._id} ref={ingredients.length === idx + 1 ? lastIngredientElementRef : null}>
                                {recipe.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                {!isRecipeMode && (
                    <IconButton
                        onClick={(e) => {
                            setIsRecipeMode(true);
                        }}
                    >
                        <EggIcon />
                    </IconButton>
                )}
                {isRecipeMode && (
                    <IconButton
                        onClick={(e) => {
                            setIsRecipeMode(false);
                        }}
                    >
                        <BookIcon />
                    </IconButton>

                )}
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="Note"
                    value={ingredient.note}
                    onChange={(e) => onNestedChange(e, index, 'ingredients', 'note')}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    label="Amount"
                    value={ingredient.quantity.amount}
                    onChange={(e) => onNestedChange(e, index, 'ingredients', 'quantity.amount')}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    fullWidth
                    label="Unit"
                    value={ingredient.quantity.unit}
                    onChange={(e) => onNestedChange(e, index, 'ingredients', 'quantity.unit')}
                />
            </Grid>
            {!isAlternative && (
                <Grid item xs={1}>
                    <Checkbox
                        checked={ingredient.optional || false}
                        onChange={(e) => onNestedChange({ target: { value: e.target.checked } }, index, 'ingredients', 'optional')}
                        name="optional"
                        color="primary"
                    />
                </Grid>
            )}
            {!isAlternative && (
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel>Group</InputLabel>
                        <Select
                            value={ingredient.group || ''}
                            onChange={(e) => onNestedChange(e, index, 'ingredients', 'group')}
                            displayEmpty
                        >
                            {groups.map((group) => (
                                <MenuItem key={group} value={group}>{group}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
            {!isAlternative && (
                <Grid item xs={1}>
                    <IconButton onClick={() => handleAddField('ingredients', {
                        ingredient: '',
                        note: '',
                        quantity: { amount: '', unit: '' }
                    })}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Grid>
            )}
            {!isAlternative && (
                <Grid item xs={1}>
                    <IconButton
                        onClick={() => handleAddAlternative(index)}
                        aria-label="add alternative ingredient"
                    >
                        <PlaylistAddIcon />
                    </IconButton>
                </Grid>
            )}
            {(index > 0 || isAlternative) && (
                <Grid item xs={1}>
                    <IconButton onClick={() => handleRemoveField('ingredients', index)}>
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Grid>
            )}
            <Modal
                open={showIngredientForm}
                onClose={() => setShowIngredientForm(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={modalStyle}>
                    <IngredientForm onSuccess={handleIngredientAdded} />
                </Box>
            </Modal>
        </React.Fragment>
    </>

}

export default RecipeIngredientSelect;