import React, {useState} from 'react';
import RecipeForm from "./RecipeForm";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import IngredientForm from "./IngredientForm";
import Register from "./Register";
import {useAuth} from "../context/AuthContext";
import RecipeSourceForm from "./RecipeSourceForm";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminPage = () => {
    const [value, setValue] = useState(0);
    const { user } = useAuth();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Recipe" {...a11yProps(0)} />
                <Tab label="Ingredient" {...a11yProps(1)} />
                <Tab label="Source" {...a11yProps(2)} />
                {user && user.role === 'admin' && <Tab label="User" {...a11yProps(3)} />}
            </Tabs>
            <TabPanel value={value} index={0}>
                <RecipeForm/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <IngredientForm/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <RecipeSourceForm/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Register/>
            </TabPanel>
        </Box>
    );
};

export default AdminPage;