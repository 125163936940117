import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL; // Ensure you have your API base URL here

// Creating axios instance
const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Retrieve the token at the time of request
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const register = async (userData) => {
    const response = await api.post(`/auth/register`, userData);

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Could not register user.');
    }

    return await response.json();
};

export const login = async (userData) => {
    const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });

    if (!response.ok) {
        throw new Error('Could not log in.');
    }

    const data = await response.json();
    localStorage.setItem('user', JSON.stringify(data.data.user));
    localStorage.setItem('token', data.data.user.token);
    return data;
};
