import React from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RecipeCollectionListItem = ({ collection, onView, onEdit, onDelete }) => {
    return (
        <>
            <ListItem>
                <ListItemText primary={collection.name} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="view" onClick={() => onView(collection._id)}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="edit" onClick={() => onEdit(collection._id)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => onDelete(collection._id)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </>
    );
};

export default RecipeCollectionListItem;
