import React, { useEffect, useState } from 'react';
import { fetchShoppingList } from '../services/shoppingListService';
import CategorySection from './CategorySection';
import { Box, Typography } from "@material-ui/core";
import { useAuth } from "../context/AuthContext";
import CircularProgress from '@mui/material/CircularProgress'; // Make sure to import CircularProgress

const ShoppingList = () => {
    const [shoppingList, setShoppingList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { socket } = useAuth();

    useEffect(() => {
        if (!socket) return;

        const handleAvailabilityChange = ({ ingredientId, available }) => {
            // Filter out the ingredient if it's now available
            if (available) {
                setShoppingList(prevList => ({
                    mandatory: prevList.mandatory.filter(ingredientDetails => ingredientDetails.ingredient._id !== ingredientId),
                    optional: prevList.optional.filter(ingredientDetails => ingredientDetails.ingredient._id !== ingredientId)
                }));
            }
        };

        // Listen for 'ingredient_availability_changed' event
        socket.on('ingredient_availability_changed', handleAvailabilityChange);

        return () => {
            if (socket) {
                socket.off('ingredient_availability_changed', handleAvailabilityChange);
            }
        };
    }, []);

    useEffect(() => {
        const loadShoppingList = async () => {
            try {
                const fetchedShoppingList = await fetchShoppingList(); // Fetch shopping list from backend API
                setShoppingList(fetchedShoppingList);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        loadShoppingList();
    }, []);

    if (!socket || loading) {
        return <CircularProgress />;
    }    
    if (error) return <div>Error: {error}</div>;

    if (!shoppingList) return null; // Handle the case when shoppingList is still null

    // Group ingredients by category
    const categorizedIngredients = shoppingList.mandatory.reduce((acc, ingredientDetails) => {
        const { category } = ingredientDetails.ingredient;
        acc[category] = acc[category] || [];
        acc[category].push({ ...ingredientDetails.ingredient, ...ingredientDetails });
        return acc;
    }, shoppingList.optional?.length ? { 'Optional': shoppingList.optional } : {});

    return (
        <div>
            {Object.entries(categorizedIngredients).length === 0 ? (
                <Box>
                    <Typography variant="body2">You've got all you need. Great!</Typography>
                </Box>
            ) : (
                Object.entries(categorizedIngredients).sort().map(([category, ingredients]) => (
                    <CategorySection key={category} category={category} ingredients={ingredients} />
                ))
            )}
        </div>
    );
};

export default ShoppingList;
