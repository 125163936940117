import React, {useState} from 'react';
import {
    TextareaAutosize,
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    IconButton, Box, Divider
} from '@mui/material';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import axios from 'axios';
import ChipInput from "./ChipInput";
import JsonEditorModal from './JsonEditorModal';
import RecipeIngredientSelect from "./RecipeIngredientSelect";
import UploadButton from "./UploadButton";
import RecipeSourceSelect from "./RecipeSourceSelect";
import RecipeSourceCreationModal from "./RecipeSourceCreationModal";

const RecipeForm = () => {
    const initialFormState = {
        name: '',
        description: '',
        additionalNotes: [''],
        categories: [],
        cookTime: {amount: '', unit: ''},
        cuisine: '',
        ingredients: [{
            ingredient: '',
            note: '',
            quantity: {amount: '', unit: ''},
            name: '',
            optional: false,
            alternatives: [],
        }],
        instructions: [''],
        prepTime: {amount: '', unit: ''},
        servings: '',
        source: '',
        tags: [],
        totalTime: {amount: '', unit: ''},
        season: '',
        nutritionPerPortion: {carbohydrates: '', fiber: '', energy: ''},
        portion: '',
        images: [''],
        groups: [],
        restingTime: {amount: '', unit: ''},
    };
    const [recipe, setRecipe] = useState(initialFormState);
    const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
    const [sourceCreationModalOpen, setSourceCreationModalOpen] = useState(false);

    const token = localStorage.getItem('token'); // Assuming token is stored in local storage

    const setCategories = (newCategories) => {
        setRecipe({...recipe, categories: newCategories});
    };
    const setTags = (newTags) => {
        setRecipe({...recipe, tags: newTags});
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setRecipe((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleNestedChange = (e, index, field, subfield) => {
        const updateNestedField = (obj, path, value) => {
            const keys = path.split('.');
            const lastKey = keys.pop();
            const lastObj = keys.reduce((acc, key) => acc[key] = acc[key] || {}, obj);
            lastObj[lastKey] = value;
        };

        setRecipe(prevState => {
            const updatedRecipe = {...prevState};
            if (Array.isArray(updatedRecipe[field])) {
                if (subfield) {
                    if (subfield.indexOf('.') >= 0) {
                        // For deep nested updates
                        const currentItem = updatedRecipe[field][index];
                        updateNestedField(currentItem, subfield, e.target.value);
                    } else {
                        // For single level nested updates within arrays
                        updatedRecipe[field][index][subfield] = e.target.value;
                    }
                } else {
                    // Direct update on the array item
                    updatedRecipe[field][index] = e.target.value;
                }
            } else {
                // This handles non-array fields, might not be necessary based on your data structure
                if (subfield) {
                    updateNestedField(updatedRecipe[field], subfield, e.target.value);
                } else {
                    updatedRecipe[field] = e.target.value;
                }
            }
            return updatedRecipe;
        });
    };
    const handleAlternativeChange = (e, index, altIndex, subfield) => {
        setRecipe(prevState => {
            const updatedRecipe = {...prevState};
            const ingredients = [...updatedRecipe.ingredients];

            const ingredient = {...ingredients[index]};
            const alternatives = [...ingredient.alternatives];

            if (subfield) {
                const alternative = {...alternatives[altIndex]};
                alternative[subfield] = e.target.value;
                alternatives[altIndex] = alternative;
            } else {
                alternatives[altIndex] = e.target.value;
            }

            ingredient.alternatives = alternatives;
            ingredients[index] = ingredient;
            return {...updatedRecipe, ingredients: ingredients};
        });
    };

    const handleFormKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    const handleAddField = (field, defaultValue) => {
        const updatedItems = [...recipe[field], defaultValue];
        setRecipe((prevState) => ({
            ...prevState,
            [field]: updatedItems,
        }));
    };
    const handleAddAlternative = (parentIndex) => {
        setRecipe(prevState => {
            const newIngredients = [...prevState.ingredients];
            if (!newIngredients[parentIndex].alternatives) {
                newIngredients[parentIndex].alternatives = [];
            }
            newIngredients[parentIndex].alternatives.push({
                ingredient: '',
                note: '',
                quantity: {amount: '', unit: ''},
                name: '',
                optional: false
            });
            return {...prevState, ingredients: newIngredients};
        });
    };
    const handleRemoveField = (field, index) => {
        const updatedItems = [...recipe[field]];
        updatedItems.splice(index, 1);
        setRecipe((prevState) => ({
            ...prevState,
            [field]: updatedItems,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const invalidIngredients = recipe.ingredients.some(ingredient => !ingredient.ingredient && !ingredient.recipeRef);
        if (invalidIngredients) {
            alert("Please ensure all ingredients have a selected value.");
            return;
        }

        try {
            await axios.post(process.env.REACT_APP_API_URL + '/recipes', sanitizeObject(recipe), {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setRecipe(initialFormState); // Reset form on success
        } catch (error) {
            console.error('Error submitting recipe:', error.response?.data || error.message);
            alert('Error submitting recipe');
        }
    };
    const handleImportSubmit = (jsonString) => {
        try {
            const parsedJson = JSON.parse(jsonString.trim());
            const ingredientsWithPlaceholders = parsedJson.ingredients.map(ingredient => ({
                ...ingredient,
                ingredient: ingredient.ingredient || '',
            }));

            const updatedRecipe = {
                ...parsedJson,
                ingredients: ingredientsWithPlaceholders,
            };

            setRecipe(updatedRecipe);
            setIsJsonModalOpen(false);
        } catch (error) {
            console.error('Error processing imported JSON:', error);
            alert('Error processing imported JSON');
        }
    };
    const handleImageUpload = (imageUrl) => {
        setRecipe(prevRecipe => ({
            ...prevRecipe,
            images: [...prevRecipe.images, imageUrl]
        }));
    };
    const handleRemoveImage = async (image, index) => {
        try {
            const filename = image.split('/').pop();

            await axios.delete(`${process.env.REACT_APP_API_URL}/image/${filename}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const updatedImages = [...recipe.images];
            updatedImages.splice(index, 1);
            setRecipe(prevRecipe => ({
                ...prevRecipe,
                images: updatedImages,
            }));
        } catch (error) {
            console.error('Failed to delete image:', error.response ? error.response.data : error);
        }
    };
    const handleRemoveAlternative = (parentIndex, altIndex) => {
        setRecipe(prevState => {
            const updatedIngredients = [...prevState.ingredients];
            const updatedAlternatives = [...updatedIngredients[parentIndex].alternatives];
            updatedAlternatives.splice(altIndex, 1); // Remove the alternative
            updatedIngredients[parentIndex].alternatives = updatedAlternatives;
            return {...prevState, ingredients: updatedIngredients};
        });
    };

    function sanitizeObject(obj) {
        const sanitized = Object.entries(obj).reduce((acc, [key, value]) => {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                acc[key] = sanitizeObject(value);
            } else if (Array.isArray(value)) {
                acc[key] = value.filter(item => item !== '');
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});

        return sanitized;
    }

    return (
        <Container maxWidth="md">
            <Typography variant="h4">Add New Recipe</Typography>
            <form onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={recipe.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={() => setIsJsonModalOpen(true)}
                        >Import JSON</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextareaAutosize
                            style={{width: "100%", height: "80px"}}
                            label="Description"
                            name="description"
                            value={recipe.description}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Cuisine"
                            name="cuisine"
                            value={recipe.cuisine}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Season"
                            name="season"
                            value={recipe.season}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Servings"
                            name="servings"
                            value={recipe.servings}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Portion"
                            name="portion"
                            value={recipe.portion}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Box mt={4} mb={2} width="100%">
                            <Typography variant="h5">Groups</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => handleAddField('groups', '')}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Grid>
                    {recipe.groups.map((instruction, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label="Group"
                                    value={recipe.groups[index]}
                                    onChange={(e) => handleNestedChange(e, index, 'groups')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => handleRemoveField('groups', index)}>
                                    <RemoveCircleOutlineIcon/>
                                </IconButton>
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                        <Box mt={4} mb={2} width="100%">
                            <Typography variant="h5">Ingredients</Typography>
                        </Box>
                    </Grid>
                    {recipe.ingredients.map((ingredient, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && (
                                <Grid item xs={12}>
                                    <Box my={2}>
                                        <Divider/>
                                    </Box>
                                </Grid>
                            )}
                            <RecipeIngredientSelect
                                key={`ingredient-${index}`}
                                index={index}
                                ingredient={ingredient}
                                onChange={handleChange}
                                onNestedChange={handleNestedChange}
                                handleAddField={handleAddField}
                                handleRemoveField={() => handleRemoveField('ingredients', index)}
                                handleAddAlternative={() => handleAddAlternative(index)}
                                handleRemoveAlternative={(altIndex) => handleRemoveAlternative(index, altIndex)}
                                groups={recipe.groups}
                                isAlternative={false}
                            />
                            {ingredient.alternatives && ingredient.alternatives.length > 0 && (
                                <Box mt={4} mb={2} width="100%">
                                    <Typography variant="h5">Alternatives</Typography>
                                </Box>
                            )}
                            {ingredient.alternatives && ingredient.alternatives.length > 0 && (
                                ingredient.alternatives.map((alt, altIndex) => (
                                    <Grid container spacing={2}
                                          style={{paddingLeft: '20px'}}>
                                        <RecipeIngredientSelect
                                            key={`alternative-${index}-${altIndex}`}
                                            index={index}
                                            altIndex={altIndex}
                                            ingredient={alt}
                                            onChange={handleChange}
                                            onNestedChange={(e, index, field, subfield) => handleAlternativeChange(e, index, altIndex, subfield)}
                                            handleRemoveField={() => handleRemoveAlternative(index, altIndex)}
                                            groups={recipe.groups}
                                            isAlternative={true}
                                        />
                                    </Grid>
                                ))
                            )}
                        </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                        <Box mt={4} mb={2} width="100%">
                            <Typography variant="h5">Instructions</Typography>
                        </Box>
                    </Grid>

                    {recipe.instructions.map((instruction, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label="Instruction"
                                    value={recipe.instructions[index]}
                                    onChange={(e) => handleNestedChange(e, index, 'instructions')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => handleAddField('instructions', '')}>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                                {index > 0 && (
                                    <IconButton onClick={() => handleRemoveField('instructions', index)}>
                                        <RemoveCircleOutlineIcon/>
                                    </IconButton>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                        <Box mt={4} mb={2} width="100%">
                            <Typography variant="h5">Additional Notes</Typography>
                        </Box>
                    </Grid>
                    {recipe.additionalNotes.map((note, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label="Note"
                                    value={recipe.additionalNotes[index]}
                                    onChange={(e) => handleNestedChange(e, index, 'additionalNotes')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => handleAddField('additionalNotes', '')}>
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                                {index > 0 && (
                                    <IconButton onClick={() => handleRemoveField('additionalNotes', index)}>
                                        <RemoveCircleOutlineIcon/>
                                    </IconButton>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={6}>
                        <Grid item xs={6}>
                            <Box mt={4} mb={2} width="100%">
                                <Typography variant="h5">Categories</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <ChipInput
                                entities={recipe.categories}
                                setEntities={setCategories}
                                label={"Categories"}
                            />
                        </Grid>
                    </Grid>


                    <Grid item xs={6}>

                        <Grid item xs={6}>
                            <Box mt={4} mb={2} width="100%">
                                <Typography variant="h5">Tags</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <ChipInput
                                entities={recipe.tags}
                                setEntities={setTags}
                                label={"Tags"}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Cook Time Amount"
                            name="cookTime.amount"
                            value={recipe.cookTime.amount}
                            onChange={(e) => handleNestedChange(e, null, 'cookTime', 'amount')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Cook Time Unit"
                            name="cookTime.unit"
                            value={recipe.cookTime.unit}
                            onChange={(e) => handleNestedChange(e, null, 'cookTime', 'unit')}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Prep Time Amount"
                            name="prepTime.amount"
                            value={recipe.prepTime.amount}
                            onChange={(e) => handleNestedChange(e, null, 'prepTime', 'amount')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Prep Time Unit"
                            name="prepTime.unit"
                            value={recipe.prepTime.unit}
                            onChange={(e) => handleNestedChange(e, null, 'prepTime', 'unit')}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Resting Time Amount"
                            name="restingTime.amount"
                            value={recipe.restingTime.amount}
                            onChange={(e) => handleNestedChange(e, null, 'restingTime', 'amount')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Resting Time Unit"
                            name="restingTime.unit"
                            value={recipe.restingTime.unit}
                            onChange={(e) => handleNestedChange(e, null, 'restingTime', 'unit')}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Total Time Amount"
                            name="totalTime.amount"
                            value={recipe.totalTime.amount}
                            onChange={(e) => handleNestedChange(e, null, 'totalTime', 'amount')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField
                            fullWidth
                            label="Total Time Unit"
                            name="totalTime.unit"
                            value={recipe.totalTime.unit}
                            onChange={(e) => handleNestedChange(e, null, 'totalTime', 'unit')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RecipeSourceSelect
                            source={recipe.source}
                            onChange={(e) => setRecipe({ ...recipe, source: e.target.value })}
                        />
                        <Button
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => setSourceCreationModalOpen(true)}
                        >
                            Add Source
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={4} mb={2} width="100%">
                            <Typography variant="h5">Images</Typography>
                        </Box>
                        {recipe.images.map((image, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        label="Image URL"
                                        value={image}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    {index > 0 && (
                                        <IconButton onClick={() => handleRemoveImage(image, index)}>
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))}
                        <UploadButton onImageUpload={(result) => {
                            const imageUrl = `${result.fileName}`;
                            handleImageUpload(imageUrl);
                        }}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit Recipe
                    </Button>
                </Grid>
            </form>
            <JsonEditorModal
                open={isJsonModalOpen}
                onClose={() => setIsJsonModalOpen(false)}
                onSubmit={handleImportSubmit}
                placeholderTemplate={recipe}
            />
            <RecipeSourceCreationModal
                isOpen={sourceCreationModalOpen}
                onClose={() => setSourceCreationModalOpen(false)}
                onSourceCreated={(newSource) => {
                    setRecipe((prevRecipe) => ({
                        ...prevRecipe,
                        source: newSource._id, // Assuming the new source has an _id field
                    }));
                    setSourceCreationModalOpen(false); // Close modal after adding
                }}
            />
        </Container>
    )
        ;
};

export default RecipeForm;
