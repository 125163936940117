import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    CircularProgress, DialogContent
} from '@material-ui/core';
import { fetchAllRecipes } from "../services/recipeService";

const RecipeSelectionDialog = ({ open, onClose, onSelect, selectedRecipes }) => {
    const [recipes, setRecipes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer  = useRef(null);

    const fetchRecipes = useCallback(async (page = 1) => {
        try {
            const fetchedRecipes = await fetchAllRecipes({ page, sortBy: 'name' });
            setHasMore(fetchedRecipes.recipes.length > 0);
            if (page === 1) {
                setRecipes(fetchedRecipes.recipes);
            } else {
                setRecipes(prev => [...prev, ...fetchedRecipes.recipes]);
            }
            setCurrentPage(page);
        } catch (error) {
            console.error('Failed to fetch recipes:', error);
        }
    }, []);

    const lastRecipeElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                fetchRecipes(currentPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [currentPage, hasMore, fetchRecipes]);

    useEffect(() => {
        if (open) {
            fetchRecipes(1);
        }
    }, [open, fetchRecipes]);

    return (
        <Dialog
            onClose={onClose}
            open={open}
            aria-labelledby="recipe-selection-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="recipe-selection-dialog-title">Select a Recipe</DialogTitle>
            <DialogContent dividers>
                <List>
                    {recipes.map((recipe, index) => (
                        <ListItem 
                            button 
                            key={recipe._id} 
                            onClick={() => onSelect(recipe)}
                            ref={recipes.length === index + 1 ? lastRecipeElementRef : null}
                        >
                            <ListItemText primary={recipe.name} />
                        </ListItem>
                    ))}
                </List>
                {hasMore && <CircularProgress />}
            </DialogContent>
        </Dialog>
    );
};

export default RecipeSelectionDialog;
