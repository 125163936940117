import React, {useState} from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Box
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import RecipeSelectionDialog from './RecipeSelectionDialog';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'; // Import remove icon

const MealComponent = ({mealType, meals, onUpdateMeal}) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSelectRecipe = (recipe) => {
        onUpdateMeal(recipe, true);
        handleCloseDialog();
    };

    const handleRemoveRecipe = (recipeId) => {
        onUpdateMeal(recipeId, false);
    };

    return (
        <div>
            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                <Typography variant="h6">{mealType}</Typography>
                <IconButton color="primary" onClick={handleOpenDialog}>
                    <AddIcon/>
                </IconButton>
            </Box>
            <List>
                {meals.map((recipe) => (
                    <ListItem key={recipe._id} component={Link} to={`/recipes/${recipe._id}`} button>
                            <ListItemText primary={recipe.name}/>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={(event) => {
                                event.preventDefault();
                                handleRemoveRecipe(recipe);
                            }}>
                                <RemoveCircleOutlineIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <RecipeSelectionDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onSelect={handleSelectRecipe}
                selectedRecipes={meals}
            />
        </div>);
};

export default MealComponent;
