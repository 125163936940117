import React, { useMemo } from 'react';
import {Typography, Grid, Box} from '@material-ui/core';
import IngredientButton from './IngredientButton';

const CategorySection = React.memo(({ category, ingredients }) => {
    const sortedIngredients = useMemo(() => ingredients.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }), [ingredients]);

    if (category === "Gruppe") {
        return null; // Do not render anything if the category is "Gruppe"
    }

    return (
        <Box>
            <Typography variant="h6">{category}</Typography>
            <hr/>
            <Grid container spacing={0} style={{flexBasis: "unset"}}> {/* No spacing from Grid */}
                {sortedIngredients.map(ingredient => (
                    <Grid item key={ingredient._id} style={{padding: '4px', flexBasis: "unset"}} xs={6} sm={4} md={3}
                          lg={2}>
                        <IngredientButton ingredient={ingredient} alternatives={ingredient.alternatives || []}/>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
});

export default CategorySection;
