import React, {createContext, useContext, useState, useEffect, useMemo} from 'react';
import * as weekPlanService from '../services/weekPlanService';

const WeekPlanContext = createContext();

export const useWeekPlan = () => useContext(WeekPlanContext);

export const WeekPlanProvider = ({ children }) => {
    const [weekPlans, setWeekPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchWeekPlans = async () => {
            setLoading(true);
            setError(null);
            try {
                // Assuming weekPlanService.fetchCurrentWeekPlan() returns the current week's plans
                const fetchedWeekPlans = await weekPlanService.fetchCurrentWeekPlan();
                setWeekPlans(fetchedWeekPlans);
            } catch (err) {
                setError('Failed to load week plans: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWeekPlans();
    }, []); // Empty dependency array means this effect runs once on component mount

    // Function to refresh week plans manually, which can be called by consumer components
    const refreshWeekPlans = async () => {
        setLoading(true);
        try {
            const fetchedWeekPlans = await weekPlanService.fetchCurrentWeekPlan();
            setWeekPlans(fetchedWeekPlans);
        } catch (err) {
            setError('Failed to refresh week plans: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    // useMemo to memoize the context value to prevent unnecessary re-renders
    const value = useMemo(() => ({
        weekPlans,
        loading,
        error,
        refreshWeekPlans,
    }), [weekPlans, loading, error]);

    return <WeekPlanContext.Provider value={value}>{children}</WeekPlanContext.Provider>;
};
