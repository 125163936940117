import React, {useEffect, useState} from 'react';
import RecipeItem from './RecipeItem';
import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    Typography, CircularProgress, Slider, Grid
} from '@mui/material';
import 'flag-icon-css/css/flag-icon.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import {fetchAllRecipes} from "../services/recipeService";

const RecipeList = ({
                        initialSearchQuery
                    }) => {
    const [recipes, setRecipes] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [sortValue, setSortValue] = useState('');
    const [selectedCuisines, setSelectedCuisines] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedNutritions, setSelectedNutritions] = useState([]);
    const [isPantryFilterActive, setIsPantryFilterActive] = useState(false);
    const [matchThresholdDummy, setMatchThresholdDummy] = useState(80);
    const [matchThreshold, setMatchThreshold] = useState(80);
    const [loading, setLoading] = useState(false);
    const [initialised, setInitialised] = useState(false);
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);

    const handleToggleCuisine = (cuisine) => {
        const currentIndex = selectedCuisines.indexOf(cuisine);
        const newSelectedCuisines = [...selectedCuisines];

        if (currentIndex === -1) {
            newSelectedCuisines.push(cuisine);
        } else {
            newSelectedCuisines.splice(currentIndex, 1);
        }

        setSelectedCuisines(newSelectedCuisines);
    };
    const handleToggleNutrition = (tag) => {
        const currentIndex = selectedNutritions.indexOf(tag);
        const newSelectedNutritions = [...selectedNutritions];

        if (currentIndex === -1) {
            newSelectedNutritions.push(tag); // Add tag to selection
        } else {
            newSelectedNutritions.splice(currentIndex, 1); // Remove tag from selection
        }

        setSelectedNutritions(newSelectedNutritions);
    };
    const handleToggleCategory = (category) => {
        const currentIndex = selectedCategories.indexOf(category);
        const newSelectedCategories = [...selectedCategories];

        if (currentIndex === -1) {
            newSelectedCategories.push(category); // Add category to selection
        } else {
            newSelectedCategories.splice(currentIndex, 1); // Remove category from selection
        }

        setSelectedCategories(newSelectedCategories);
    };

    const categories = ["Grundzutaten", "Hauptgericht", "Dessert", "Vorspeise", "Auflauf", "Sauce", "Suppe", "Cocktail"].sort();
    const cuisine = ["Deutsch", "Italienisch", "Mexikanisch", "Französisch", "Amerikanisch", "Österreichisch", "Schweizerisch", "Polynesisch", "Japanisch"].sort();
    const nutritionTags = ["Vegan", "Vegetarisch", "Glutenfrei", "Keto", "Laktosefrei"].sort();

    const cuisineCountryCodes = {
        Deutsch: 'de',
        Italienisch: 'it',
        Mexikanisch: 'mx',
        "Französisch": 'fr',
        Amerikanisch: 'us',
        Japanisch: 'jp',
        Polynesisch: 'pf',
        Schweizerisch: 'ch',
        'Österreichisch': 'at'
        // Add mappings for other cuisines
    };

    const scrollableContainerStyle = {
        display: 'flex',
        overflowX: 'auto',
        gap: '10px', // Space between chips
        padding: '10px', // Padding inside the scrollable container
        '&::-webkit-scrollbar': {
            display: 'none', // Optionally hide the scrollbar for webkit browsers
        },
        // Additional styles for scrollbar customization if needed
    };

    const fetchNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const fetchRecipes = async () => {
        if (loading || !hasMore) return; // Prevent fetching if already loading or no more data

        setLoading(true);

        const queryParams = {
            page,
            sortBy: sortValue ? sortValue + ':asc' : '',
            cuisine: selectedCuisines.join(','),
            categories: selectedCategories.join(','),
            nutrition: selectedNutritions.join(','),
            usePantryFilter: isPantryFilterActive,
            matchThreshold: matchThreshold / 100, // Convert to decimal for backend
            searchTerm: searchQuery
        };

        try {
            const data = await fetchAllRecipes(queryParams);
            if (data.recipes.length === 0) {
                setHasMore(false);
            } else {
                let newRecipes = [];
                if (page !== 1) {
                    newRecipes = recipes.concat(data.recipes);
                } else {
                    newRecipes = data.recipes;
                }
                setRecipes(newRecipes);
            }
        } catch (error) {
            setHasMore(false);
        } finally {
            setInitialised(true);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchRecipes();
    }, [page]); // Now this effect only re-runs when the page changes

    // Reset recipes and page count when filters are changed
    useEffect(() => {
        let forceFetch = (page === 1 && initialised);

        setRecipes([]);
        setPage(1);
        setHasMore(true);

        if (forceFetch) {
            fetchRecipes();
        }
    }, [selectedCuisines, selectedCategories, selectedNutritions, isPantryFilterActive, sortValue, matchThreshold, searchQuery]);

    useEffect(() => {
        setSearchQuery(initialSearchQuery);
    }, [initialSearchQuery]);

    return (
        <Box>
            <Box sx={{p: 2}}>
                <Typography variant="h6" gutterBottom>
                    Kategorie
                </Typography>
                <Box sx={scrollableContainerStyle}>
                    <Stack direction="row" spacing={1}>
                        {categories.map((category) => (
                            <Chip
                                key={category}
                                label={category}
                                onClick={() => handleToggleCategory(category)}
                                color={selectedCategories.includes(category) ? "primary" : "default"}
                                variant={selectedCategories.includes(category) ? "filled" : "outlined"}
                            />
                        ))}
                    </Stack>
                </Box>

                <Typography variant="h6" gutterBottom>
                    Küche
                </Typography>
                <Box sx={scrollableContainerStyle}>
                    <Stack direction="row" spacing={1} sx={{my: 2}}>
                        {cuisine.map((cuisine) => (
                            <Chip
                                key={cuisine}
                                icon={
                                    <span className={`flag-icon flag-icon-${cuisineCountryCodes[cuisine]}`}></span>
                                }
                                label={`${cuisine}`}
                                onClick={() => handleToggleCuisine(cuisine)}
                                color={selectedCuisines.includes(cuisine) ? "primary" : "default"}
                                variant={selectedCuisines.includes(cuisine) ? "filled" : "outlined"}
                            />
                        ))}
                    </Stack>
                </Box>

                <Typography variant="h6" gutterBottom>
                    Ernährung
                </Typography>
                <Box sx={scrollableContainerStyle}>
                    <Stack direction="row" spacing={1} sx={{my: 2}}>
                        {nutritionTags.map((tag) => (
                            <Chip
                                key={tag}
                                label={tag}
                                onClick={() => handleToggleNutrition(tag)}
                                color={selectedNutritions.includes(tag) ? "primary" : "default"}
                                variant={selectedNutritions.includes(tag) ? "filled" : "outlined"}
                            />
                        ))}
                    </Stack>
                </Box>

                <FormControlLabel
                    control={
                        <Switch
                            checked={isPantryFilterActive}
                            onChange={(event) => setIsPantryFilterActive(event.target.checked)}
                            name="pantryFilter"
                            color="primary"
                        />
                    }
                    label="Nur Zutaten aus Speisekammer"
                />
                {(isPantryFilterActive) && (
                    <>
                        <Typography gutterBottom>
                            Übereinstimmungsschwelle
                        </Typography>
                        <Slider
                            value={matchThresholdDummy}
                            onChange={(e, newValue) => setMatchThresholdDummy(newValue)}
                            onChangeCommitted={(e, newValue) => {
                                setMatchThresholdDummy(newValue)
                                setMatchThreshold(newValue)
                            }}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={100}
                        />
                    </>

                )}


                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="sort-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-label"
                        value={sortValue}
                        label="Sortieren nach"
                        onChange={(e) => setSortValue(e.target.value)}
                    >
                        <MenuItem value=""><em>-</em></MenuItem>
                        <MenuItem value="name">Name</MenuItem>
                        <MenuItem value="totalTime">Gesamtzeit</MenuItem> {/* New sort option */}
                    </Select>
                </FormControl>
            </Box>
            <InfiniteScroll
                dataLength={recipes.length}
                next={fetchNextPage}
                hasMore={hasMore}
                loader={<CircularProgress />}
                endMessage={
                    <Typography textAlign="center" marginTop={2}>
                        You have seen all recipes
                    </Typography>
                }
            >
                {recipes.map(recipe => (
                    <RecipeItem key={recipe._id} recipe={recipe}/>
                ))}
            </InfiniteScroll>
        </Box>
    );
};

export default RecipeList;
