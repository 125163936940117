import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import DayComponent from './DayComponent';
import { fetchWeekPlanByDate, updateWeekPlan } from '../services/weekPlanService';
import { useWeekPlan } from "../context/WeekPlanContext";

const getStartOfWeek = (date = new Date()) => {
    const now = new Date(date);
    now.setUTCHours(0, 0, 0, 0); // Set hours to start of the day in UTC

    // Calculate the day of the week, with Monday (1) through Sunday (7) in UTC
    let dayOfWeek = now.getUTCDay();
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek; // Adjust Sunday to be day 7 instead of day 0

    // Calculate the difference to last Monday in UTC
    const diff = dayOfWeek - 1;

    // Adjust the date back to Monday in UTC
    now.setUTCDate(now.getUTCDate() - diff);

    return now;
};




const getWeekDaysSorted = (startOfWeek) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(day.getDate() + i);
        days.push({
            day: day.toLocaleDateString('de-DE', { weekday: 'long' }),
            meals: [],
            date: day.toISOString().split('T')[0] // ISO format: YYYY-MM-DD
        });
    }
    return days;
};

const WeekPlannerComponent = () => {
    const [currentWeekStartDate, setCurrentWeekStartDate] = useState(getStartOfWeek());
    const [weekPlan, setWeekPlan] = useState({ days: getWeekDaysSorted(currentWeekStartDate) });
    const { refreshWeekPlans } = useWeekPlan();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleNextPreviousWeek = (direction) => {
        const newWeekStartDate = new Date(currentWeekStartDate);
        newWeekStartDate.setDate(newWeekStartDate.getDate() + 7 * direction);
        setCurrentWeekStartDate(newWeekStartDate);
        initializeWeekPlan(newWeekStartDate);
    };
    const initializeWeekPlan = async (startDate) => {
        const date = startDate.toISOString().split('T')[0];
        try {
            const fetchedWeekPlan = await fetchWeekPlanByDate(date);
            setWeekPlan(fetchedWeekPlan || { days: getWeekDaysSorted(startDate) });
        } catch (error) {
            console.error('Error initializing week plan:', error);
            setWeekPlan({ days: getWeekDaysSorted(startDate) });
        }
    };
    const handleNextWeek = () => handleNextPreviousWeek(1);
    const handlePreviousWeek = () => handleNextPreviousWeek(-1);

    useEffect(() => {
        initializeWeekPlan(currentWeekStartDate);
    }, [currentWeekStartDate]);

    const handleUpdateDay = async (dayName, updatedMeals) => {
        const updatedDays = weekPlan.days.map(day => {
            const dayDateUTC = new Date(day.date).toISOString().split('T')[0];
            const dayNameUTC = new Date(dayName).toISOString().split('T')[0];
            if (dayDateUTC === dayNameUTC) {
                const updatedDay = { ...day, meals: updatedMeals };
                return updatedDay;
            }
            return day;
        });

        const updatedWeekPlan = { ...weekPlan, days: updatedDays };
        setWeekPlan(updatedWeekPlan);
        await updateWeekPlan(updatedWeekPlan._id, updatedWeekPlan);
        refreshWeekPlans();
    };

    if (isLoading) {
        return <div>Loading recipes...</div>;
    }

    if (error) {
        return <div>Error fetching recipes: {error}</div>;
    }

    return (
        <div>
            <Button onClick={handlePreviousWeek}>Previous Week</Button>
            <Button onClick={handleNextWeek}>Next Week</Button>
            <Grid container spacing={3} justify="space-around">
                {weekPlan.days.map((dayData, index) => (
                    <Grid item xs={12} sm={6} md={true} key={index}>
                        <DayComponent dayData={dayData} onUpdateDay={handleUpdateDay}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default WeekPlannerComponent;
