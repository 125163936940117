import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import RecipeSourceForm from './RecipeSourceForm'; // Adjust the import path as necessary

const RecipeSourceCreationModal = ({ isOpen, onClose, onSourceCreated }) => {
    const handleClose = () => {
        onClose();
    };

    const handleSourceCreated = (source) => {
        onSourceCreated(source);
        onClose(); // Close modal after source creation
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Add New Source</DialogTitle>
            <DialogContent>
                {/* Here you can directly use RecipeSourceForm or adjust it to fit the modal context */}
                <RecipeSourceForm onFormSubmit={handleSourceCreated} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RecipeSourceCreationModal;
