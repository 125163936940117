import React, {useEffect, useState} from 'react';
import { Box, Button, Grid, TextField, Typography, Rating } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const RatingForm = ({ recipeId, existingRating, onRatingSubmit }) => {
    const [rating, setRating] = useState(existingRating ? existingRating.rating / 2 : 10); // Adjusted for a scale of 0-20 in half-star increments
    const [comment, setComment] = useState(existingRating ? existingRating.comment : '');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (existingRating) {
            setRating(existingRating.rating / 2); // Convert to 1-10 scale for the Rating component
            setComment(existingRating.comment);
        }
    }, [existingRating]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const submissionData = {
            rating: rating * 2, // Convert back to 0-20 scale
            comment,
            recipe: recipeId
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/ratings${existingRating ? `/${existingRating._id}` : ''}`, {
                method: existingRating ? 'PATCH' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming the token is stored in localStorage
                },
                body: JSON.stringify(submissionData)
            });
            if (!response.ok) throw new Error('Failed to submit rating');
            const data = await response.json();
            if (onRatingSubmit) onRatingSubmit(data);
        } catch (error) {
            console.error('Error submitting rating:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Typography variant="h6">Rate this recipe</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography gutterBottom>Rating</Typography>
                    <Rating
                        name="customized-rating"
                        defaultValue={2.5}
                        precision={0.5}
                        max={10}
                        value={rating}
                        onChange={(event, newValue) => setRating(newValue)}
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        icon={<StarIcon fontSize="inherit" />}
                        halfIcon={<StarHalfIcon fontSize="inherit" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="comment"
                        label="Comment (Optional)"
                        name="comment"
                        multiline
                        rows={4}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit Rating'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RatingForm;
