import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RecipeDetails from './components/RecipeDetails';
import { Box, createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import NavigationHeader from './components/NavigationHeader';
import SearchResultsPage from './components/SearchResultsPage';
import DiscoverPage from './components/DiscoverPage';
import Login from './components/Login';
import { ProtectedRoute, RoleBasedRoute } from './components/ProtectedRoute';
import { AuthProvider } from "./context/AuthContext";
import { PantryProvider } from "./context/PantryContext";
import LandingPage from './components/LandingPage';
import PantryPage from './components/PantryPage';
import AdminPage from './components/AdminPage';
import { RecipeCollectionPage } from "./components/RecipeCollectionPage";
import WeekPlannerComponent from "./components/WeekPlannerComponent";
import ShoppingList from "./components/ShoppingList";
import { WeekPlanProvider } from "./context/WeekPlanContext";
import Footer from "./components/Footer";

const theme = createTheme({
    palette: {
        primary: {
            main: '#91b783'
        },
        secondary: {
            main: '#FF9800D8'
        }
    }
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthProvider>
                    <PantryProvider>
                        <WeekPlanProvider>
                            <Box display="flex" flexDirection="column" minHeight="100vh">
                                <CssBaseline />
                                <NavigationHeader />
                                <Box component="main" flexGrow={1}>
                                    <Routes>
                                        <Route path="/" element={<LandingPage />} />
                                        <Route path="/recipes/:id"
                                            element={<ProtectedRoute element={<RecipeDetails />} />} />
                                        <Route path="/search"
                                            element={<ProtectedRoute element={<SearchResultsPage />} />} />
                                        <Route path="/entdecken" element={<ProtectedRoute element={<DiscoverPage />} />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/speisekammer" element={<ProtectedRoute element={<PantryPage />} />} />
                                        <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} />} />
                                        <Route path="/listen"
                                            element={<ProtectedRoute element={<RecipeCollectionPage />} />} />
                                        <Route path="/wochenplan"
                                            element={<ProtectedRoute element={<WeekPlannerComponent />} />} />
                                        <Route path="/einkaufsliste"
                                            element={<ProtectedRoute element={<ShoppingList />} />} />
                                    </Routes>
                                </Box>
                                <Footer />
                            </Box>
                        </WeekPlanProvider>
                    </PantryProvider>
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
