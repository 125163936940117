import React, { useState, useRef } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CustomInputContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '6px 12px',
    borderRadius: '4px',
    ':focus-within': {
        borderColor: 'blue',
    }
});

function ChipInput({ entities, setEntities, label }) {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace' && !inputValue) {
            // Remove the last entity when backspace is pressed on an empty input
            setEntities(entities.slice(0, -1));
        } else if (event.key === 'Enter') {
            // Add the current inputValue as a new entity when Enter is pressed
            event.preventDefault(); // Prevent the default form submission
            const newEntity = inputValue.trim();
            if (newEntity) {
                addEntity(newEntity);
            }
            setInputValue('');
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        if (value.includes(',')) {
            // Split the input on commas and add each part as an entity
            const parts = value.split(',');
            parts.forEach(part => {
                const trimmedPart = part.trim();
                if (trimmedPart) {
                    addEntity(trimmedPart);
                }
            });
            setInputValue(''); // Clear input field after adding
        } else {
            setInputValue(value);
        }
    };

    const addEntity = (newEntity) => {
        if (newEntity && !entities.includes(newEntity)) { // Prevent adding duplicate entities
            setEntities([...entities, newEntity]);
        }
    };

    return (
        <CustomInputContainer onClick={() => inputRef.current?.focus()}>
            {entities.map((entity, index) => (
                <Chip
                    key={index}
                    label={entity}
                    onDelete={() => setEntities(entities.filter((_, i) => i !== index))}
                    style={{ margin: '2px' }}
                />
            ))}
            <input
                placeholder={label}
                ref={inputRef}
                value={inputValue}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                style={{ border: 'none', outline: 'none', flex: '1' }}
            />
        </CustomInputContainer>
    );
}

export default ChipInput;
