import React from 'react';
import { Box, Typography, Rating, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const RatingsList = ({ ratings }) => {
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                User Ratings
            </Typography>
            <List>
                {ratings.map((rating, index) => (
                    <React.Fragment key={rating._id}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt="User" src={rating.user.profilePicture} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Rating name="read-only" value={rating.rating / 2} precision={0.5} readOnly max={10} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />}
                                secondary={
                                    <>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {/* Display first name and last name if available, otherwise display email */}
                                            {rating.user.firstName && rating.user.lastName ? `${rating.user.firstName} ${rating.user.lastName}` : rating.user.email}
                                        </Typography>
                                        — {rating.comment}
                                    </>
                                }
                            />
                        </ListItem>
                        {index < ratings.length - 1 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default RatingsList;
