import React from 'react';
import {
    Button,
    Typography,
    Box,
    Container,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    createTheme,
    ThemeProvider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const FeatureSection = ({ title, description, imageUrl }) => (
    <Grid item xs={12} sm={6} md={4}>
        <Card raised sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardMedia component="img" image={imageUrl} alt={title} height="140" />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    </Grid>
);

const LandingPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const features = [
        { title: 'Discover Recipes', description: 'Explore thousands of recipes from around the world.', imageUrl: '/images/discover-recipes.png' },
        { title: 'Meal Planning', description: 'Plan your meals for the week with our interactive planner.', imageUrl: '/images/meal-planning.png' },
        { title: 'Shopping List', description: 'Generate shopping lists from your meal plans.', imageUrl: '/images/shopping-list.png' },
        { title: 'Community', description: 'Connect with a community of food lovers.', imageUrl: '/images/community.png' },
    ];

    return (
        <Container maxWidth="lg" sx={{ my: 4 }}>
            {user ? (
                <Box textAlign="center">
                    <Typography variant="h4" component="h1" gutterBottom fontFamily={"Monotype Corsiva Italic"} fontStyle={'italic'}>
                        Welcome back, {user.firstName}!
                    </Typography>
                </Box>
            ) : (
                <>
                    <Box textAlign="center" mb={5}>
                        <Typography variant="h3" component="h1" gutterBottom fontFamily={"Monotype Corsiva Italic"} fontStyle={'italic'}>
                            Welcome to PantryChef!
                        </Typography>
                        <Typography variant="h5" component="p" sx={{ mb: 4 }}>
                            Your culinary journey starts here.
                        </Typography>
                        <Button variant="contained" color="primary" sx={{ mx: 2 }} onClick={() => navigate('/login')}>
                            Login
                        </Button>
                    </Box>
                    <Grid container spacing={4}>
                        {features.map((feature, index) => (
                            <FeatureSection key={index} {...feature} />
                        ))}
                    </Grid>
                </>
            )}
        </Container>
    );
};

export default LandingPage;
