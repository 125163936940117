const API_URL = process.env.REACT_APP_API_URL;

export const fetchShoppingList = async () => {
    const token = localStorage.getItem('token'); // Assuming token is stored in local storage

    try {
        const response = await fetch(`${API_URL}/shopping-lists`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch shopping list');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching shopping list: ${error.message}`);
    }
};
