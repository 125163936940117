import React, {useMemo, useState} from 'react';
import {usePantry} from '../context/PantryContext';
import {List, ListItem, ListItemText, Paper, Popover, Typography} from '@material-ui/core';
import './IngredientButton.css';
import IconButton from "@mui/material/IconButton"; // Import the CSS file here
import PlusIcon from '@material-ui/icons/Add'; // Import Plus icon
import InfoIcon from '@material-ui/icons/InfoRounded';

const IngredientButton = React.memo(({ ingredient }) => {
    const {toggleIngredientAvailability} = usePantry();
    const [plusAnchorEl, setPlusAnchorEl] = useState(null);
    const [infoAnchorEl, setInfoAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        toggleIngredientAvailability(ingredient._id, !ingredient.available);
    };
    const handlePlusClick = (event) => {
        event.stopPropagation(); // Prevents the ingredient button click from firing
        setPlusAnchorEl(event.currentTarget);
    };
    const handleInfoClick = (event) => {
        event.stopPropagation(); // Prevents the ingredient button click from firing
        setInfoAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setPlusAnchorEl(null);
        setInfoAnchorEl(null);
    };

    const plusOpen = Boolean(plusAnchorEl);
    const infoOpen = Boolean(infoAnchorEl);

    const plusId = plusOpen ? 'plus-popover' : undefined;
    const infoId = infoOpen ? 'info-popover' : undefined;

    // Applying the background color conditionally based on availability
    const buttonStyle = useMemo(() => ({
        backgroundColor: ingredient.available ? '#368030' : '#d23838',
        position: 'relative', // To position the plus icon absolutely within the button
    }), [ingredient.available]);

    const placeholderImage = `${process.env.PUBLIC_URL}/images/placeholder.png`;
    let imageSrc = placeholderImage;

    if (ingredient.icon) {
        if (ingredient.icon.indexOf('base64') >= 0) {
            imageSrc = ingredient.icon;
        } else {
            imageSrc = process.env.REACT_APP_IMAGE_URL + ingredient.icon
        }
    }

    const additionalInformation = ingredient.additionalInformation || [];

    return (
        <>
            <Paper
                elevation={1}
                onClick={handleClick}
                className="responsive-button-base"
                style={buttonStyle}
            >
                {ingredient.alternatives && ingredient.alternatives.length > 0 && (
                    <IconButton
                        size="small"
                        onClick={handlePlusClick}
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            color: 'white', // Adjust as needed
                        }}
                    >
                        <PlusIcon />
                    </IconButton>
                )}
                {ingredient.additionalInformation && ingredient.additionalInformation.length > 0 && (
                    <IconButton
                        size="small"
                        onClick={handleInfoClick}
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: 'white', // Adjust as needed
                        }}
                    >
                        <InfoIcon />
                    </IconButton>
                )}
                <img
                    src={imageSrc}
                    alt={ingredient.name}
                    className="ingredient-icon"
                />
                <Typography className="responsive-button-base-text">
                    {ingredient.name}
                </Typography>
            </Paper>
            {ingredient.alternatives && ingredient.alternatives.length > 0 && (
                <Popover
                    id={plusId}
                    open={plusOpen}
                    anchorEl={plusAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {ingredient?.alternatives.map((alt, index) => (
                        // Recursively render IngredientButton for each alternative
                        <IngredientButton key={index} ingredient={alt.ingredient} />
                    ))}
                </Popover>
            )}
            {additionalInformation.length > 0 && (
                <Popover
                    id={infoId}
                    open={infoOpen}
                    anchorEl={infoAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <List>
                        {additionalInformation.map((info, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={info.recipe}
                                    secondary={`Menge: ${info.amount} ${info.unit}, Notizen: ${info.notes || '-'}, Datum: ${new Date(info.date).toLocaleDateString('de-DE')}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Popover>
            )}
        </>
    );
});

export default IngredientButton;
