import React, {useState} from 'react';
import { Modal, Box, Typography, Button } from '@material-ui/core';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json'; // Import syntax highlighting for JSON
import 'prismjs/themes/prism.css'; // Import a PrismJS theme


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400, // Adjust width as needed
    maxHeight: '80vh', // Maximum height before scrolling
    overflowY: 'auto', // Enable vertical scrolling
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const JsonEditorModal = ({ open, onClose, onSubmit, placeholderTemplate }) => {
    const [code, setCode] = useState(JSON.stringify(placeholderTemplate, null, "\t"));

    const handleCodeChange = newCode => {
        setCode(newCode);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="json-editor-modal-title"
            aria-describedby="json-editor-modal-description"
        >
            <Box sx={style}>
                <Typography id="json-editor-modal-title" variant="h6">
                    Import Recipe JSON
                </Typography>
                <Editor
                    value={code}
                    onValueChange={handleCodeChange}
                    highlight={code => highlight(code, languages.json)}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        minHeight: '200px',
                        border: '1px solid black',
                        borderRadius: '4px',
                        overflowY: 'auto',
                    }}
                />
                <Button
                    style={{ marginTop: '20px' }}
                    onClick={() => {
                        onSubmit(code);
                        onClose();
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    );
};

export default JsonEditorModal;