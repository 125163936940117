import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RecipeCollectionList from './RecipeCollectionList'; // Adjust the import path as needed

// Assuming you have a service to communicate with your backend
import { getCollections, deleteCollection } from '../services/collectionService';
import {useNavigate} from "react-router-dom";

export const RecipeCollectionPage = () => {
    const [collections, setCollections] = useState([]);
    const [ownCollections, setOwnCollections] = useState([]);
    const [sharedCollections, setSharedCollections] = useState([]);

    const user = JSON.parse(localStorage.getItem('user'));
    const currentUserID = user._id;
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch collections
        const fetchData = async () => {
            const allCollections = await getCollections();
            const ownCollections = allCollections.filter(collection => collection.owner._id === currentUserID);
            const sharedCollections = allCollections.filter(collection => collection.owner._id !== currentUserID);
            setCollections(ownCollections);
            setSharedCollections(sharedCollections);
        };

        fetchData();
    }, []);

// Function handlers for view, edit, and delete actions
    const handleViewCollection = (id) => {
        // Navigate to the collection detail/view page
        navigate(`/collections/view/${id}`);
    };

    const handleEditCollection = (id) => {
        // Navigate to the collection edit page
        navigate(`/collections/edit/${id}`);
    };

    const handleDeleteCollection = async (id) => {
        try {
            await deleteCollection(id);
            // Refresh collections list after deletion by filtering out the deleted collection
            setCollections(collections.filter(collection => collection._id !== id));
        } catch (error) {
            console.error("Failed to delete collection:", error);
            // Optionally, show an error message to the user
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Meine Listen
            </Typography>

            <Button startIcon={<AddIcon />} variant="contained" color="primary">
                Neue Liste erstellen
            </Button>

            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                Meine Listen
            </Typography>
            <RecipeCollectionList
                collections={ownCollections}
                onView={handleViewCollection}
                onEdit={handleEditCollection}
                onDelete={handleDeleteCollection}
            />
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                Geteilte Listen
            </Typography>
            <RecipeCollectionList
                collections={sharedCollections}
                onView={handleViewCollection}
                onEdit={handleEditCollection}
                onDelete={handleDeleteCollection}
            />        </Container>
    );
};
