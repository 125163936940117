import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Footer = () => {
    return (
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#91b783', color: 'text.secondary' }}>
            <Container maxWidth="lg">
                <Typography variant="body1" align="center" gutterBottom>
                    Discover More:
                </Typography>
                <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
                    <Link href="/about" underline="hover" color="inherit">
                        About Us
                    </Link>
                    <Link href="/contact" underline="hover" color="inherit">
                        Contact
                    </Link>
                    <Link href="/privacy" underline="hover" color="inherit">
                        Privacy Policy
                    </Link>
                </Box>
                <Typography variant="caption" display="block" align="center" sx={{ mt: 2 }}>
                    Fonts made available by <Link href="https://www.onlinewebfonts.com/icon" underline="hover" color="inherit" rel="noopener noreferrer" target="_blank">Online Web Fonts</Link> licensed under <Link href="http://creativecommons.org/licenses/by/4.0/" underline="hover" color="inherit" rel="noopener noreferrer" target="_blank">CC BY 4.0</Link>
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
